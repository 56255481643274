import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const MobilePay = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m69.69 17.19h-26.14c-3.3 0-5.98 2.68-5.98 5.98v13.04h-20.2c-1.62 0-2.94 1.32-2.94 2.94v19.63c0 1.62 1.32 2.94 2.94 2.94h20.2v15.77c0 3.3 2.68 5.98 5.98 5.98h26.14c3.3 0 5.98-2.68 5.98-5.98v-54.33c0-3.3-2.68-5.98-5.98-5.98zm-53.23 21.96c0-.5.41-.91.91-.91h36.21c.5 0 .91.41.91.91v3.81h-38.03zm0 6.19h38.03v1.94h-38.03zm0 13.44v-9.12h38.03v9.12c0 .5-.41.91-.91.91h-36.21c-.5 0-.91-.41-.91-.91zm57.21 18.7c0 2.19-1.79 3.98-3.98 3.98h-26.14c-2.19 0-3.98-1.78-3.98-3.98v-15.77h14.01c1.62 0 2.94-1.32 2.94-2.94v-19.63c0-1.62-1.32-2.94-2.94-2.94h-14.01v-13.04c0-2.19 1.79-3.98 3.98-3.98h26.14c2.19 0 3.98 1.78 3.98 3.98z" />
        <path d="m61.08 23.48h-8.91c-.55 0-1 .45-1 1s.45 1 1 1h8.91c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m56.62 72.72c-1.44 0-2.62 1.17-2.62 2.62s1.17 2.62 2.62 2.62 2.62-1.17 2.62-2.62-1.17-2.62-2.62-2.62z" />
      </g>
    </BaseIcon>
  ),
)
MobilePay.displayName = 'MobilePay'
export default MobilePay
