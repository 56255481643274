import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const NoPortrait = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 48 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 9.21606C19.0294 9.21606 15 13.2455 15 18.2161C15 23.1866 19.0294 27.216 24 27.216C28.9706 27.216 33 23.1866 33 18.2161C33 13.2455 28.9706 9.21606 24 9.21606ZM13 18.2161C13 12.1409 17.9249 7.21606 24 7.21606C30.0752 7.21606 35 12.1409 35 18.2161C35 24.2912 30.0752 29.216 24 29.216C17.9249 29.216 13 24.2912 13 18.2161Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0002 35.2161C17.2107 35.2161 11.0803 37.2769 6.59407 40.5887L5.40625 38.9795C10.2632 35.3941 16.8177 33.2161 24.0002 33.2161C31.1826 33.2161 37.7372 35.3941 42.594 38.9795L41.4062 40.5887C36.92 37.2769 30.7896 35.2161 24.0002 35.2161Z"
    />
  </BaseIcon>
))
NoPortrait.displayName = 'NoPortrait'
export default NoPortrait
