'use client'

// useFormState has been renamed to useActionState in React 19
// change this import when updating (use "react" instead of "react-dom")
// https://react.dev/reference/react/useActionState
import { useFormStatus, useFormState as useActionState } from 'react-dom'

import { Alert, Button } from 'flowbite-react'
import { Check, Refresh } from 'flowbite-react-icons/outline'
import { ExclamationCircle } from 'flowbite-react-icons/solid'
import { useTranslations } from 'next-intl'
import { useTransition } from 'react'
import { env } from 'next-runtime-env'
import Script from 'next/script'
import { fields } from './fields'
import type { Props } from '.'
import { submitForm } from '@/app/actions'
import { cn } from '@/lib/utils'
import useSearchParam from '@/lib/hooks/use-search-param'
import { RichText } from '@/components/RichText'

type FieldType<T extends keyof typeof fields> =
  (typeof fields)[T] extends React.ComponentType<infer P> ? P : never

const initialState = {
  message: '',
}

declare const window:
  | ({
      KROT?: {
        getSolution: () => Promise<unknown>
        setup: (k: string) => void
      }
    } & Window)
  | undefined

export const Form = ({ form }: { form: Props['form'] }) => {
  const t = useTranslations('Form')
  const {
    id: formID,
    confirmationMessage_html: confirmationMessage,
    confirmationType,
    submitButtonLabel,
  } = form ?? {}

  const originURL =
    useSearchParam('originURL') ||
    (typeof window !== 'undefined' ? window.location.pathname : '')
  const messageDefaultValue = useSearchParam('message')

  const submitFormWithExtraArgs = submitForm.bind(
    null,
    Number(formID),
    originURL,
  )
  const [state, formAction] = useActionState(
    submitFormWithExtraArgs,
    initialState,
  )
  const [, startTransition] = useTransition()

  const hasSent = state?.message === 'sent'
  const hasErrors = !hasSent && state?.message
  const captchaKey = env('NEXT_PUBLIC_CAPTCHA_KEY')

  const wrappedAction = (payload: FormData) => {
    startTransition(async () => {
      if (
        typeof window !== 'undefined' &&
        'KROT' in window &&
        typeof window.KROT === 'object'
      ) {
        try {
          const s = await window.KROT.getSolution()
          const captcha = encodeURIComponent(JSON.stringify(s))
          payload.append('captcha', captcha) // Append captcha to formData
        } catch (error) {
          // swallow error
        }
      }
      return formAction(payload)
    })
  }

  return (
    <div
      className={cn('flex flex-col', hasSent && 'items-center justify-center')}
    >
      {captchaKey && <CaptchaScriptLoader captchaKey={captchaKey} />}
      {hasSent && confirmationType === 'message' && confirmationMessage && (
        <Alert color="success" className="mb-4 w-full" icon={Check}>
          <RichText content={confirmationMessage} />
        </Alert>
      )}
      {hasErrors && (
        <Alert color="failure" className="mb-4 w-full" icon={ExclamationCircle}>
          {t('formSubmissionError')}
        </Alert>
      )}
      {!hasSent && (
        <form id={String(formID)} action={wrappedAction}>
          <div className="-mx-5 flex flex-wrap">
            {form?.fields?.map((field) => {
              const fieldType = field.blockType as keyof typeof fields
              const Field = fields?.[fieldType]
              if (fieldType == 'textarea') {
                ;(field as FieldType<typeof fieldType>).defaultValue =
                  messageDefaultValue
              }
              if (Field) {
                return (
                  <Field
                    key={field.id}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...(field as FieldType<typeof fieldType>)}
                  />
                )
              }
              return null
            })}
          </div>
          <p className="py-3 text-xs text-gray-500">
            * {t('requiredFieldExplanation')}
          </p>
          <SubmitButton label={submitButtonLabel ?? 'Submit'} />
        </form>
      )}
    </div>
  )
}

const SubmitButton = ({ label }: { label: string }) => {
  const { pending } = useFormStatus()
  return (
    <Button className="mt-2" type="submit" disabled={pending}>
      {label} {pending && '...'}{' '}
      {pending && <Refresh className="speed ml-2 animate-spin" />}
    </Button>
  )
}

const CaptchaScriptLoader = ({ captchaKey }: { captchaKey: string }) => {
  return (
    <Script
      src="https://www.captcha.eu/sdk.js"
      strategy="afterInteractive"
      onLoad={() => {
        if (
          typeof window !== 'undefined' &&
          'KROT' in window &&
          typeof window.KROT === 'object'
        ) {
          window.KROT.setup(captchaKey)
        }
      }}
    />
  )
}
