import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Frage = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m50 75c-14.34 0-26-11.66-26-26s11.66-26 26-26 26 11.66 26 26-11.66 26-26 26zm0-50c-13.23 0-24 10.77-24 24s10.77 24 24 24 24-10.77 24-24-10.77-24-24-24z" />
        <path d="m49.96 55.51c-.83 0-1.5-.67-1.5-1.5 0-2.31 1.41-4.37 3.68-5.39 2.1-.88 3.46-2.98 3.39-5.23-.06-2.89-2.47-5.29-5.36-5.35h-.22c-1.45 0-2.76.52-3.79 1.52-1.09 1.05-1.7 2.46-1.7 3.96 0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5c0-2.33.93-4.5 2.62-6.12 1.58-1.52 3.65-2.36 5.82-2.36h.37c4.46.1 8.17 3.81 8.27 8.27.1 3.48-1.99 6.72-5.2 8.06-1.17.53-1.88 1.51-1.88 2.64 0 .83-.67 1.5-1.5 1.5z" />
        <circle cx="50" cy="61.85" r="2.41" />
      </g>
    </BaseIcon>
  ),
)
Frage.displayName = 'Frage'
export default Frage
