import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Geld = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m58.24 75.01c-10.17 0-19.25-6.09-23.13-15.52l-.48-1.16h-6.52c-.87 0-1.58-.71-1.58-1.58s.71-1.58 1.58-1.58h5.58l-.26-2.11c-.13-1.06-.19-2.05-.19-3.04 0-.48.02-.96.04-1.44l.11-1.99h-5.6c-.87 0-1.58-.71-1.58-1.58s.71-1.58 1.58-1.58h6.29l.43-1.29c3.41-10.25 12.95-17.13 23.73-17.13 5.3 0 10.37 1.64 14.65 4.73.71.51.87 1.5.36 2.21-.29.4-.79.65-1.3.65-.33 0-.64-.1-.91-.3-3.74-2.7-8.16-4.13-12.79-4.13-8.4 0-15.35 4.43-19.75 12.57l-1.26 2.68h18.13c.87 0 1.58.71 1.58 1.58s-.71 1.58-1.58 1.58h-18.74l-.14 1.73c-.12 1.75-.04 3.72.23 5.27l.26 1.57h18.07c.87 0 1.58.71 1.58 1.58s-.71 1.58-1.58 1.58h-17.25l1.7 2.84c3.94 6.59 11.12 10.68 18.74 10.68 4.73 0 9.23-1.49 13.02-4.31.27-.2.59-.31.93-.31.52 0 .98.23 1.29.65.25.33.35.75.29 1.16-.06.43-.28.79-.62 1.04-4.33 3.22-9.49 4.93-14.91 4.93z" />
    </BaseIcon>
  ),
)
Geld.displayName = 'Geld'
export default Geld
