import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Rechner = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m66.87 80.83h-34.22c-2.75 0-5-2.24-5-5v-52.92c0-2.75 2.24-5 5-5h34.22c2.76 0 5 2.24 5 5v52.92c0 2.76-2.24 5-5 5zm-34.23-60.92c-1.65 0-3 1.34-3 3v52.92c0 1.65 1.34 3 3 3h34.22c1.65 0 3-1.34 3-3v-52.92c0-1.65-1.34-3-3-3z" />
        <path d="m41.64 52.86h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m41.64 61.62h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m41.64 70.37h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m52.59 52.86h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m52.59 61.62h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m52.59 70.37h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m63.4 52.86h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m63.4 61.62h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m63.4 70.37h-5.66c-.55 0-1-.45-1-1s.45-1 1-1h5.66c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m64.09 40h-28.67c-.55 0-1-.45-1-1v-11.95c0-.55.45-1 1-1h28.67c.55 0 1 .45 1 1v11.95c0 .55-.45 1-1 1zm-27.67-2h26.67v-9.95h-26.67z" />
      </g>
    </BaseIcon>
  ),
)
Rechner.displayName = 'Rechner'
export default Rechner
