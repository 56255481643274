import React from 'react'
import { Label } from 'flowbite-react'
import styles from './index.module.css'
import { cn } from '@/lib/utils'

interface FieldsetProps {
  width: number | null
  children: React.ReactNode
  className?: string
}

export const Fieldset = ({ width, children, className }: FieldsetProps) => {
  const style: Record<string, string> = width
    ? { '--custom-width': `${width}%` }
    : {}

  return (
    <fieldset
      className={cn(
        'flex flex-col gap-2 px-5 py-3',
        styles['md-field-width'],
        className,
      )}
      style={style}
    >
      {children}
    </fieldset>
  )
}
export const FieldsetLabel = ({
  id,
  children,
  required,
}: {
  id: string | null
  children: React.ReactNode
  required: boolean | null
}) => {
  if (!id || !children) return null
  return (
    <Label htmlFor={id}>
      {children}
      {required && '*'}
    </Label>
  )
}
