import { Fieldset } from '../Fieldset'
import type { FormMessageFieldFragment } from '../'
import { FragmentOf } from '@/graphql'
import { RichText } from '@/components/RichText'

export const Message = ({
  message_html,
}: FragmentOf<typeof FormMessageFieldFragment>) => {
  return (
    <Fieldset width={100} className="py-0">
      <RichText content={message_html} className="text-sm" />
    </Fieldset>
  )
}
