import { TextInput } from 'flowbite-react'
import { useState, useEffect } from 'react'
import { formatNumber } from '@/lib/utils'

interface FormattedNumericInputProps {
  name: string
  value: number
  setValue: (value: number) => void
  className?: string
  min: number
  max: number
  icon?: React.ComponentProps<typeof TextInput>['icon']
  sizing?: React.ComponentProps<typeof TextInput>['sizing']
  color?: React.ComponentProps<typeof TextInput>['color']
}

// Valides input to fit min/max range and formats the input value for display
export const FormattedNumericInput = ({
  name,
  value,
  setValue,
  className,
  min,
  max,
  icon,
  sizing,
  color,
}: FormattedNumericInputProps) => {
  const [formatted, setFormatted] = useState(true)

  const handleFocus = () => {
    setFormatted(false)
  }

  const handleBlur = () => {
    // Tolerate smaller values until input blured to not disrupt typing
    if (value < min) {
      setValue(min)
      return
    }
    setFormatted(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove all decimal or thousand seperators from the input value
    let inputValue = parseInt(e.target.value.replace(/\./g, '') || '0')

    // never allow values above max
    if (inputValue > max) {
      inputValue = max
    }
    setValue(inputValue)
  }

  // force min value after 3 seconds of not typing
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value < min) {
        setValue(min)
      }
    }, 3000)
    return () => clearTimeout(timeout)
  }, [value, setValue, min])

  return (
    <TextInput
      value={formatted ? formatNumber(value, 0) : value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      autoComplete="off"
      inputMode="numeric"
      {...{ name, min, max, className, icon, sizing, color }}
    />
  )
}
