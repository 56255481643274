'use client'

import { Label, TextInput, Button } from 'flowbite-react'
import { useSearchParams, usePathname, useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

export const SearchBar = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  const t = useTranslations('SearchBar')

  function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const term = formData.get('query')?.toString()
    if (term && term.length < 3) return
    const params = new URLSearchParams(searchParams)

    params.delete('page')
    if (term) {
      params.set('query', term)
    } else {
      params.delete('query')
    }
    router.replace(`${pathname}?${params.toString()}`, { scroll: false })
  }
  return (
    <form
      className="flex flex-row justify-center gap-4"
      onSubmit={handleSearch}
    >
      <fieldset className="max-w-md flex-1">
        <div className="mb-2">
          <Label htmlFor="query">{t('queryInputLabel')}</Label>
        </div>
        <div className="flex gap-2">
          <TextInput
            type="search"
            key={searchParams.get('query')?.toString()}
            id="query"
            name="query"
            placeholder={t('queryInputPlaceholder')}
            defaultValue={searchParams.get('query')?.toString()}
            className="w-full"
            sizing="md"
            minLength={3}
            color="white"
          />
          <Button type="submit" size="sm">
            {t('queryButtonLabel')}
          </Button>
        </div>
      </fieldset>
    </form>
  )
}
