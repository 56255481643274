import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Zeit = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m50.62 75.1c-15.59 0-28.27-12.68-28.27-28.27s12.68-28.27 28.27-28.27 28.27 12.68 28.27 28.27-12.68 28.27-28.27 28.27zm0-54.54c-14.49 0-26.27 11.79-26.27 26.27s11.79 26.27 26.27 26.27 26.27-11.79 26.27-26.27-11.79-26.27-26.27-26.27z" />
        <path d="m50.72 48h-12.91c-.59 0-1.07-.48-1.07-1.07s.48-1.07 1.07-1.07h12.33l9.61-14.69c.32-.49.99-.63 1.49-.31s.63.99.31 1.49l-9.93 15.17c-.2.3-.54.48-.9.48z" />
      </g>
    </BaseIcon>
  ),
)
Zeit.displayName = 'Zeit'
export default Zeit
