import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Justiz = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m78.91 46.75-7.37-15.46c-.27-.56-.73-.97-1.28-1.21v-5.27c0-.55-.45-1-1-1h-38.53c-.55 0-1 .45-1 1v5.27c-.55.24-1.01.65-1.28 1.21l-7.37 15.45c-.04.09-.07.17-.09.26v.04s-.05.1-.05.1 0 .03-.01.04c-.03.12-.05.24-.07.36s0 .01 0 .02 0 .07 0 .11v.13s0 0 0 .01v.03s0 .08 0 .12v.04s0 0 0 0v.01.03s0 0 0 0c.02.3.08.56.17.8 1.71 4.22 5.6 7.05 9.7 7.05 4.7 0 8.25-3.54 9.68-7.05l.04-.15c.26-.66.24-1.36-.06-2l-7.37-15.46c-.27-.56-.73-.97-1.28-1.21v-4.27h17.37v46.18s.02.06.02.1h-8.73c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h19.43c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-8.73s.02-.06.02-.1v-46.11h17.16v4.27c-.55.24-1.01.65-1.28 1.21l-7.37 15.45c-.04.09-.07.17-.09.26v.04s-.05.1-.05.1 0 .03-.01.04c-.03.12-.05.24-.07.36s0 .01 0 .02 0 .07 0 .11v.13s0 0 0 .01v.03s0 .08 0 .12v.04s0 0 0 0v.01.03s0 0 0 0c.02.3.08.56.17.8 1.71 4.22 5.6 7.05 9.7 7.05 4.7 0 8.25-3.54 9.68-7.05l.04-.15c.26-.66.24-1.36-.06-2zm-48.19 7.2c-3.67 0-6.48-2.72-7.72-5.51h15.41c-1.25 2.79-4.05 5.51-7.69 5.51zm7.29-7.51h-14.57l6.81-14.27c.13-.27.38-.3.48-.3s.34.03.47.3l6.81 14.28zm30.77-14.27c.13-.27.38-.3.48-.3s.34.03.47.3l6.81 14.28h-14.57l6.81-14.27zm.47 21.78c-3.67 0-6.48-2.72-7.72-5.51h15.41c-1.25 2.79-4.05 5.51-7.69 5.51z" />
    </BaseIcon>
  ),
)
Justiz.displayName = 'Justiz'
export default Justiz
