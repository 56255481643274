import { Select as UISelect } from 'flowbite-react'
import { useFormStatus } from 'react-dom'
import { Fieldset, FieldsetLabel } from '../Fieldset'
import type { FormSelectFieldFragment } from '../'
import { FragmentOf } from '@/graphql'

export const Select = ({
  name,
  label,
  options,
  required,
  width,
  defaultValue,
  id,
}: FragmentOf<typeof FormSelectFieldFragment>) => {
  const { pending } = useFormStatus()
  return (
    <Fieldset width={width}>
      <FieldsetLabel {...{ id, required }}>{label}</FieldsetLabel>
      {(options?.length ?? 0) > 0 && (
        <UISelect
          name={name}
          id={id!}
          required={!!required}
          disabled={pending}
          defaultValue={defaultValue ?? undefined}
        >
          {options?.map(
            (option) =>
              option.value && (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ),
          )}
        </UISelect>
      )}
    </Fieldset>
  )
}
