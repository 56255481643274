import { useState, useEffect } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'

const useSearchParam = (key: string) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [searchParamValue, setSearchParamValue] = useState('')

  useEffect(() => {
    const value = searchParams.get(key)
    if (value) {
      setSearchParamValue(value)
      const newParams = new URLSearchParams(searchParams.toString())
      newParams.delete(key)
      // update URL so that the value is removed from the query string
      router.replace(`?${newParams.toString()}`, { scroll: false })
    }
  }, [searchParams, router, key])

  return searchParamValue
}

export default useSearchParam
