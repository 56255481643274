import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Prozent = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m34.15 73.46c-.29 0-.58-.09-.82-.26-.3-.22-.5-.54-.56-.91s.03-.74.25-1.04l33.67-46.64c.26-.36.69-.58 1.13-.58.3 0 .58.09.82.27.3.22.5.54.56.91s-.03.74-.25 1.04l-33.67 46.64c-.26.36-.69.58-1.14.58z" />
        <path d="m36.87 43.39c-4.92 0-8.93-4-8.93-8.92s4-8.92 8.93-8.92 8.92 4 8.92 8.92-4 8.92-8.92 8.92zm0-15.05c-3.38 0-6.12 2.75-6.12 6.12s2.75 6.12 6.12 6.12 6.12-2.75 6.12-6.12-2.75-6.12-6.12-6.12z" />
        <path d="m64.46 73.49c-4.92 0-8.92-4-8.92-8.92s4-8.92 8.92-8.92 8.93 4 8.93 8.92-4 8.92-8.93 8.92zm0-15.05c-3.38 0-6.12 2.75-6.12 6.12s2.75 6.12 6.12 6.12 6.12-2.75 6.12-6.12-2.75-6.12-6.12-6.12z" />
      </g>
    </BaseIcon>
  ),
)
Prozent.displayName = 'Prozent'
export default Prozent
