import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Gold = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m37.11 49.39c-.33 0-.63-.16-.82-.42-.19-.27-.23-.61-.12-.91l5.14-14.31c.14-.4.52-.66.94-.66h15.5c.42 0 .8.27.94.67l5.15 14.3c.11.31.06.65-.12.91-.19.27-.49.42-.82.42zm5.84-14.31-4.42 12.31h22.94s-4.43-12.31-4.43-12.31z" />
        <path d="m20.51 70.44c-.33 0-.63-.16-.82-.42-.19-.27-.23-.61-.12-.91l5.14-14.31c.14-.4.52-.66.94-.66h15.5c.42 0 .8.27.94.67l5.15 14.3c.11.31.06.65-.12.91-.19.27-.49.42-.82.42zm5.84-14.31-4.42 12.31h22.94s-4.43-12.31-4.43-12.31z" />
        <path d="m53.7 70.44c-.33 0-.63-.16-.82-.42-.19-.27-.23-.61-.12-.91l5.14-14.31c.14-.4.52-.66.94-.66h15.5c.42 0 .8.27.94.67l5.15 14.3c.11.31.06.65-.12.91-.19.27-.49.42-.82.42zm5.85-14.31-4.42 12.31h22.94s-4.43-12.31-4.43-12.31z" />
        <path d="m50 27.68c-.55 0-1-.45-1-1v-7.81c0-.55.45-1 1-1s1 .45 1 1v7.81c0 .55-.45 1-1 1z" />
        <path d="m25.97 36.63c-.25 0-.51-.1-.7-.29l-6.03-5.98c-.39-.39-.4-1.02 0-1.41.39-.39 1.02-.4 1.41 0l6.03 5.98c.39.39.4 1.02 0 1.41-.2.2-.45.3-.71.3z" />
        <path d="m74.03 36.63c-.26 0-.51-.1-.71-.3-.39-.39-.39-1.03 0-1.41l6.03-5.98c.39-.39 1.03-.39 1.41 0s.39 1.03 0 1.41l-6.03 5.98c-.2.19-.45.29-.7.29z" />
      </g>
    </BaseIcon>
  ),
)
Gold.displayName = 'Gold'
export default Gold
