'use client'

import { useTranslations } from 'next-intl'
import { useConsentManager } from '@/lib/hooks/use-consent-manager'

export default function ConsentManager() {
  const t = useTranslations('ConsentManager')
  const { showChoices } = useConsentManager()
  return (
    <button
      className="cursor-pointer underline hover:underline lg:no-underline"
      onClick={showChoices}
    >
      {t('label')}
    </button>
  )
}
