import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Nachhaltigkeit = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <path d="m73.22 82.97c-.42 0-.77-.28-1-.79l-.08-.21-1.69-3.9c-1.39-3.21-2.83-6.53-4.29-9.78-.57-1.26-1.24-2.51-1.89-3.72-.3-.55-.59-1.1-.89-1.67 0 0-.79.24-.99.3-4.14 1.18-7.95 1.75-11.65 1.75-1.89 0-3.76-.16-5.58-.46-7.86-1.32-13.87-5.52-17.88-12.49-2.59-4.5-4.2-9.74-5.07-16.47-.68-5.22-.8-10.75-.41-17.38.05-.86.42-1.21 1.26-1.21 0 0 1.52.05 2.22.08 1.97.07 4.02.13 6.02.31 7.29.66 13.1 2.14 18.29 4.66 8.03 3.9 13.28 9.96 15.59 18.02 1.82 6.32 1.82 13.04.03 20.55-.16.65-.11 1.15.17 1.71 2.09 4.22 7.71 16.28 8.26 17.76.18.47.35.94.54 1.42.13.33.13.65.01.92-.11.24-.32.43-.6.53-.13.05-.26.07-.39.07zm-49.39-62.99c-.04.7-.07 1.38-.09 2.06-.17 6.57.19 11.9 1.13 16.79 1.15 5.98 2.95 10.55 5.68 14.37 3.65 5.11 8.74 8.22 15.12 9.25 1.65.26 3.35.4 5.06.4 3.48 0 7.19-.2 11-1.64.43-.16.66-.3.66-.3-.69-1.04-1.39-2.13-2.08-3.18-1.53-2.34-3.11-4.76-4.75-7.07-4.02-5.64-8.26-10.12-12.97-13.72-2.96-2.26-5.87-3.94-8.9-5.13-.19-.07-.38-.15-.55-.24-.55-.29-.73-.79-.49-1.36.17-.4.48-.63.87-.63.13 0 .26.03.4.08.8.3 1.74.67 2.68 1.11 5.41 2.59 10.29 6.42 15.37 12.05 3.82 4.24 7.44 9.25 11.06 15.31.09.15.18.29.3.47l.13.2.09-.43c.08-.37.15-.7.2-1.02 1.09-6.45.85-12.09-.75-17.26-2.18-7.04-6.81-12.39-13.77-15.9-5.36-2.7-11.52-4.24-19.39-4.83-1.35-.1-2.7-.16-4.08-.23-.62-.03-1.25-.06-1.89-.09l-.05.95z" />
  </BaseIcon>
))
Nachhaltigkeit.displayName = 'Nachhaltigkeit'
export default Nachhaltigkeit
