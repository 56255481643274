import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Wertanlage = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <path d="m49.63 76.22c-.31 0-.62-.18-.9-.54-2.12-2.62-19.55-24.27-19.55-24.27-2.27-2.82-4.54-5.64-6.81-8.46-.12-.15-.2-.31-.29-.49-.04-.08-.08-.16-.13-.24l.1-.31c.07-.17.13-.32.21-.45.98-1.57 1.96-3.13 2.93-4.7l2.29-3.66c1.23-1.96 2.46-3.93 3.68-5.9.31-.51.72-.74 1.32-.74h17.16s11.44 0 17.16 0c.59 0 1 .23 1.31.74 2.37 3.81 4.75 7.61 7.12 11.41l1.75 2.8c.4.63.37.99-.1 1.57l-25.37 31.49c-.32.4-.65.81-.98 1.21-.29.35-.59.53-.9.53zm-.14-4.26 6.31-17.07 4.37-11.73h-21.2zm13.08-28.83c-.21 0-.3.07-.37.27-.29.81-.59 1.63-.9 2.44l-9 24.14c-.06.16-.11.32-.17.48l-.09.27.06-.05 8.66-10.83 13.45-16.69s-.16 0-.21 0h-3.03c-2.8 0-5.59 0-8.39 0zm-15.91 26.83s-.04-.07-.04-.1c-.68-1.86-6.08-16.62-6.08-16.62-1.2-3.29-2.4-6.57-3.59-9.86-.06-.17-.13-.26-.35-.26-2.77 0-5.54 0-8.31 0h-3c-.06 0-.12 0-.19.01h-.03s21.6 26.81 21.6 26.81zm-10.54-28.82-4.18-11.42-7.15 11.42zm23.34-.01c-.04-.06-.08-.11-.12-.15l-9.56-11.76c-.06-.08-.13-.16-.15-.17-.03 0-.09.09-.16.17l-9.54 11.74s-.08.1-.12.17h19.66zm15 0-7.15-11.44-4.26 11.44zm-36.54-.84 9.6-11.81h-13.92zm23.36-.08 4.37-11.73h-13.9z" />
  </BaseIcon>
))
Wertanlage.displayName = 'Wertanlage'
export default Wertanlage
