'use client'

import { useEffect, useState } from 'react'
import { useIntersectionObserver } from '@/lib/hooks/user-intersection-observer'

interface Props {
  children: React.ReactNode
  hiddenElement: React.ReactNode
}

export function Observer({ children, hiddenElement }: Readonly<Props>) {
  const [isClient, setIsClient] = useState(false)
  const [setRef, isIntersecting, entry] = useIntersectionObserver()

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      <div ref={setRef}>{children}</div>
      {isClient &&
        !isIntersecting &&
        (entry?.boundingClientRect?.top ?? 0) < window.innerHeight &&
        hiddenElement}
    </>
  )
}
