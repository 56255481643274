import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Tablet = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m70.2 83.24h-40.4c-2.76 0-5-2.24-5-5v-58.7c0-2.75 2.24-5 5-5h40.39c2.76 0 5 2.24 5 5v58.7c0 2.75-2.24 5-5 5zm-40.4-66.7c-1.65 0-3 1.34-3 3v58.7c0 1.65 1.34 3 3 3h40.39c1.65 0 3-1.34 3-3v-58.7c0-1.65-1.34-3-3-3z" />
        <path d="m53.87 23.08h-7.75c-.55 0-1-.45-1-1s.45-1 1-1h7.75c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <circle cx="50" cy="75.7" r="2.62" />
      </g>
    </BaseIcon>
  ),
)
Tablet.displayName = 'Tablet'
export default Tablet
