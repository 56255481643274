import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Diagramm2 = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m48.64 80.84c-15.59 0-28.27-12.68-28.27-28.27s12.68-28.27 28.27-28.27c.55 0 1 .45 1 1v25.78l23.43-9.56c.1-.12.23-.23.39-.29.51-.21 1.1.03 1.31.54 1.42 3.43 2.14 7.06 2.14 10.8 0 15.59-12.68 28.27-28.27 28.27zm-1-54.52c-14.02.53-25.27 12.1-25.27 26.25s11.78 26.27 26.27 26.27 26.27-11.79 26.27-26.27c0-3.09-.53-6.11-1.58-8.99l-24.31 9.92c-.31.12-.66.09-.94-.1s-.44-.5-.44-.83z" />
        <path d="m54.19 44.72c-.2 0-.39-.06-.56-.17-.28-.19-.44-.5-.44-.83v-27.27c0-.55.45-1 1-1 11.54 0 21.81 6.91 26.18 17.59.1.25.1.52 0 .77-.1.24-.3.44-.54.54l-25.25 10.3c-.12.05-.25.07-.38.07zm1-27.25v24.76l22.93-9.35c-4.12-9.1-12.95-15.04-22.93-15.41z" />
      </g>
    </BaseIcon>
  ),
)
Diagramm2.displayName = 'Diagramm2'
export default Diagramm2
