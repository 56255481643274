import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Schutz = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m48.52 83.21c-.57 0-1.16-.11-1.77-.31-.71-.25-1.45-.68-2.08-1.23-4.64-4.01-9.26-8.06-13.87-12.1l-4.23-3.71c-1.34-1.17-1.96-2.55-1.96-4.35v-41.58c0-1.06.34-1.4 1.39-1.4h44.71c1.21 0 1.53.31 1.53 1.53v13.23 28.02c0 1.94-.68 3.44-2.16 4.73-5.93 5.16-11.85 10.35-17.76 15.53-1.24 1.09-2.52 1.64-3.8 1.64zm-21.78-60.08v8.97 29.21c0 1.26.47 2.29 1.43 3.14 4.14 3.61 8.27 7.23 12.4 10.84l5.3 4.64c.7.61 1.49 1.18 2.55 1.18s1.86-.57 2.56-1.19l6.31-5.53c3.78-3.31 7.55-6.61 11.34-9.91 1-.87 1.48-1.93 1.48-3.25-.01-9.58-.01-19.16-.01-28.74v-9.31s.01-2.49.01-2.49h-43.38v2.44z" />
        <path d="m48.21 62.29c-.57 0-1.09-.31-1.37-.8l-7.21-12.71c-.43-.76-.16-1.73.6-2.16.24-.13.51-.21.78-.21.57 0 1.1.31 1.38.8l5.73 10.1 11.31-23.11c.27-.55.81-.89 1.42-.89.24 0 .47.05.69.16.38.19.66.51.8.91s.11.83-.07 1.21l-12.62 25.8c-.26.53-.78.87-1.37.89h-.05z" />
      </g>
    </BaseIcon>
  ),
)
Schutz.displayName = 'Schutz'
export default Schutz
