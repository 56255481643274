'use client'

import { useState, useEffect, useTransition, useOptimistic } from 'react'
import { Label, TextInput, Button, Dropdown, Checkbox } from 'flowbite-react'
import { useSearchParams, useRouter } from 'next/navigation'
import { useLocale, useTranslations } from 'next-intl'
import { ChevronDown, Refresh } from 'flowbite-react-icons/outline'
import { getServices, type Service } from '@/app/actions'
import { Locale } from '@/app/config'
import { cn } from '@/lib/utils'

export const LocationFilters = () => {
  const searchParams = useSearchParams()
  const router = useRouter()
  const t = useTranslations('LocationFilters')
  const locale = useLocale() as Locale
  const [isPending, startTransition] = useTransition()

  const segmentsOptions = [
    { id: 'private', name: t('segments.private') },
    { id: 'corporate', name: t('segments.corporate') },
    { id: 'real_estate', name: t('segments.real_estate') },
  ]

  // track selected URL parameters locally so that components react instantly
  // to user input instead of having to wait for router URL update
  const [optimisticSegments, setOptimisticSegments] = useOptimistic(
    searchParams.get('segments'),
  )
  const [optimisticServices, setOptimisticServices] = useOptimistic(
    searchParams.get('services'),
  )

  // fetch the list of available services from CMS when component mounts
  const [servicesOptions, setServicesOptions] = useState<Service[]>([])
  useEffect(() => {
    const fetchServices = async () => {
      const result = await getServices(locale)
      setServicesOptions(result.filter((service) => service !== null))
    }
    void fetchServices()
  }, [locale])

  // Convert the currently selected parameters into a comma seperated list
  // or show the "Show all" option when nothing is selected
  // so that user sees what's currently selected
  const selectedSegmentsList = getSelectedOptionsList(
    searchParams,
    'segments',
    segmentsOptions,
    t('servicesShowAllLabel'),
  )

  const selectedServicesList = getSelectedOptionsList(
    searchParams,
    'services',
    servicesOptions,
    t('segmentsShowAllLabel'),
  )

  // update URL params when form is submitted
  function updateQuery(param: string) {
    const params = new URLSearchParams(searchParams)
    params.delete('page')
    if (param) {
      params.set('query', param)
    } else {
      params.delete('query')
    }
    // Start a transition so that isPending can be used to make elements inactive while results load
    // More on useTransition and useOptimistic in Next.js https://github.com/vercel/next.js/discussions/41934#discussioncomment-8996669
    startTransition(() => {
      router.replace(`?${params.toString()}`, { scroll: false })
    })
  }

  function updateSegments(param: string) {
    const params = new URLSearchParams(searchParams)
    params.set('segments', param)
    params.delete('page')
    startTransition(() => {
      setOptimisticSegments(param)
      router.replace(`?${params.toString()}`, { scroll: false })
    })
  }

  function updateServices(param: string) {
    const params = new URLSearchParams(searchParams)
    params.set('services', param)
    params.delete('page')
    startTransition(() => {
      setOptimisticServices(param)
      router.replace(`?${params.toString()}`, { scroll: false })
    })
  }

  return (
    <form
      className="flex flex-col gap-4 lg:flex-row"
      onSubmit={(event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        let query = formData?.get('query')?.toString() ?? ''
        query = query.length < 3 ? '' : query
        updateQuery(query)
      }}
    >
      <fieldset className="flex-1">
        <div className="mb-2">
          <Label htmlFor="query">{t('queryInputLabel')}</Label>
        </div>
        <div className="flex gap-2">
          <TextInput
            key={searchParams.get('query')}
            type="search"
            id="query"
            name="query"
            defaultValue={searchParams.get('query')?.toString()}
            className="w-full"
            sizing="md"
            minLength={3}
            color="white"
          />
          <Button
            type="submit"
            size="sm"
            className="whitespace-nowrap disabled:cursor-progress"
            disabled={isPending}
          >
            {t('queryButtonLabel')}
          </Button>
        </div>
      </fieldset>
      <fieldset className="min-w-0 flex-1">
        <div className="mb-2">
          <Label htmlFor="filter-segments">{t('segmentsInputLabel')}</Label>
        </div>
        <Dropdown
          dismissOnClick={false}
          label=""
          renderTrigger={() => (
            <button
              type="button"
              id="filter-segments"
              className="flex w-full items-center justify-between rounded-lg border border-gray-300 bg-white p-2.5 text-left text-sm text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <span className="truncate">{selectedSegmentsList}</span>
              <ChevronDown className="relative top-0.5 -mt-1 text-gray-500" />
            </button>
          )}
        >
          {segmentsOptions.map((segment) => {
            return (
              <Dropdown.Item
                key={segment?.id}
                as={Label}
                className={cn(isPending && 'cursor-progress opacity-75')}
              >
                <Checkbox
                  id={`segment-${segment?.id}`}
                  name="segments"
                  value={segment?.id ?? undefined}
                  onChange={(event) => {
                    const formData = new FormData(
                      event.target.form ?? undefined,
                    )
                    const segments = formData.getAll('segments')
                    updateSegments(segments.join(','))
                  }}
                  className="mr-2 cursor-pointer"
                  key={optimisticSegments}
                  disabled={isPending}
                  checked={
                    !!optimisticSegments
                      ?.split(',')
                      ?.includes(String(segment?.id))
                  }
                />
                {segment?.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown>
      </fieldset>
      <fieldset className="min-w-0 flex-1">
        <div className="mb-2">
          <Label htmlFor="filter-services">{t('servicesInputLabel')}</Label>
        </div>
        <Dropdown
          dismissOnClick={false}
          label=""
          renderTrigger={() => (
            <button
              id="filter-services"
              type="button"
              className="flex w-full items-center justify-between rounded-lg border border-gray-300 bg-white p-2.5 text-left text-sm text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <span className="truncate">{selectedServicesList}</span>
              <ChevronDown className="relative top-0.5 -mt-1 text-gray-500" />
            </button>
          )}
        >
          {servicesOptions.length ? (
            <>
              {servicesOptions.map((service) => {
                return (
                  <Dropdown.Item
                    key={service?.id}
                    as={Label}
                    className={cn(isPending && 'cursor-progress opacity-75')}
                  >
                    <Checkbox
                      id={`services-${service?.id}`}
                      name="services"
                      value={service?.id ?? undefined}
                      onChange={(event) => {
                        const formData = new FormData(
                          event.target.form ?? undefined,
                        )
                        const services = formData.getAll('services')
                        updateServices(services.join(','))
                      }}
                      className="mr-2"
                      key={optimisticServices}
                      disabled={isPending}
                      checked={
                        !!optimisticServices
                          ?.split(',')
                          ?.includes(String(service?.id))
                      }
                    />
                    {service?.name}
                  </Dropdown.Item>
                )
              })}
            </>
          ) : (
            <Refresh
              className="m-2 mx-auto size-6 animate-spin text-gray-400"
              strokeWidth={1}
            />
          )}
        </Dropdown>
      </fieldset>
    </form>
  )
}

function getSelectedOptionsList(
  searchParams: URLSearchParams,
  key: string,
  map: Service[] | Record<'id' | 'name', string>[],
  showAllLabel: string,
) {
  return (
    // split params by comma, remove duplicates
    [...new Set(searchParams.get(key)?.split(','))]
      // map ids to names
      .map((arg) => map.find((obj) => String(obj.id) === arg)?.name)
      // filter out undefined and null values
      .filter((item) => !!item)
      // join names with comma for display or show showAllLabel
      .join(', ') || showAllLabel
  )
}
