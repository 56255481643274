import { useCallback } from 'react'

declare const window:
  | ({
      dr_revokeChoice?: () => void
    } & Window)
  | undefined

export function useConsentManager() {
  const showChoices = useCallback(() => {
    window?.dr_revokeChoice?.()
  }, [])

  return {
    showChoices,
  }
}
