'use client'

import { RefreshRouteOnSave as PayloadLivePreview } from '@payloadcms/live-preview-react'
import { useRouter } from 'next/navigation'
import React from 'react'
import { env } from 'next-runtime-env'

export const RefreshRouteOnSaveClient = () => {
  const router = useRouter()
  const CMS_URL = env('NEXT_PUBLIC_CMS_URL')!
  return (
    <PayloadLivePreview refresh={() => router.refresh()} serverURL={CMS_URL} />
  )
}
