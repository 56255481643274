import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Standort = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m50.18 79.71c-.08-.06-.16-.12-.25-.18-.34-.25-.66-.48-.9-.74-5.93-6.27-10.71-12.57-14.61-19.25-2.73-4.68-4.43-8.73-5.35-12.74-1.69-7.41-.23-14.09 4.35-19.84 3.55-4.45 8.24-7.13 13.95-7.95.43-.06.85-.12 1.28-.17l.51-.06h2.32l.69.09c.52.06 1.04.13 1.56.22 7 1.22 12.34 5.06 15.85 11.41 2.12 3.83 2.98 8.12 2.58 12.76-.37 4.31-1.7 8.58-4.18 13.43-3.44 6.73-8.17 13.32-14.45 20.12l-.19.21c-.57.62-1.16 1.26-1.76 1.83-.26.25-.57.47-.9.71-.07.05-.14.1-.21.15zm.3-58.9c-1.46 0-2.97.17-4.48.51-4.26.95-7.86 3.15-10.71 6.54-3.8 4.53-5.39 9.83-4.73 15.76.38 3.41 1.48 6.83 3.58 11.1 2.43 4.97 5.71 9.98 10.01 15.34 1.19 1.48 2.43 2.94 3.64 4.36.56.66 1.13 1.33 1.69 2l.68.81.92-.8c.1-.08.15-.13.2-.18 5.2-5.58 9.29-10.87 12.5-16.19 2.56-4.25 4.34-8.18 5.43-12.02 1.13-4 1.32-7.62.57-11.07-1.29-5.94-4.56-10.48-9.71-13.5-3.01-1.76-6.24-2.65-9.59-2.65z" />
        <path d="m50.29 50.28c-2.74-.02-5.31-1.12-7.25-3.09-1.91-1.95-2.94-4.5-2.91-7.18.07-5.7 4.54-10.17 10.17-10.17 5.7.04 10.25 4.63 10.21 10.23-.03 5.63-4.59 10.21-10.15 10.21zm.03-18.42c-4.49 0-8.15 3.66-8.17 8.16 0 2.19.84 4.26 2.38 5.8 1.54 1.55 3.59 2.4 5.78 2.4 4.48 0 8.15-3.65 8.17-8.13 0-2.19-.84-4.25-2.4-5.81-1.55-1.55-3.59-2.41-5.76-2.41z" />
      </g>
    </BaseIcon>
  ),
)
Standort.displayName = 'Standort'
export default Standort
