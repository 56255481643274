import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Wechselkurs = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m56.71 54.35c-.19-.25-.48-.4-.8-.4-.21 0-.41.07-.58.19-1.57 1.17-3.44 1.79-5.41 1.79-3.62 0-6.73-2.13-8.19-5.2h6.82c.54 0 .99-.44.99-.99s-.44-.99-.99-.99h-7.5c-.13-.61-.2-1.25-.2-1.91 0-.39.03-.78.08-1.16h7.75c.54 0 .99-.44.99-.99s-.44-.99-.99-.99h-7.27c1.28-3.46 4.6-5.94 8.51-5.94 1.93 0 3.76.59 5.32 1.72.17.12.37.18.57.18.32 0 .62-.15.81-.41.32-.44.22-1.06-.22-1.38-1.89-1.37-4.13-2.09-6.47-2.09-5 0-9.23 3.34-10.59 7.91h-2.49c-.54 0-.99.44-.99.99s.44.99.99.99h2.09c-.04.38-.06.77-.06 1.16 0 .65.07 1.29.18 1.91h-2.07c-.54 0-.99.44-.99.99s.44.99.99.99h2.61c1.58 4.18 5.61 7.17 10.33 7.17 2.39 0 4.67-.75 6.59-2.18.21-.16.35-.39.39-.65s-.03-.52-.18-.73z" />
      <path d="m67.16 34.66 4.5 1.2c-3.47-7.39-11.66-15.23-23.86-14.67-6.86.32-13.37 3.41-18 8.49s-6.52 10.62-6.65 17.23h-1.76c-.24-7.94 3.97-19.38 15.84-24.96 6.66-3.13 13.57-3.56 20.54-1.14s11.87 7.27 15.41 13.75l1.08-3.99c.09-.35.45-.55.79-.46l.52.14c.35.09.55.45.46.8-.19.73-.38 1.44-.57 2.15-.37 1.39-.74 2.77-1.12 4.16-.18.67-.58.97-1.18.81-2.14-.55-4.27-1.13-6.46-1.72-.34-.09-.55-.44-.47-.79l.13-.53c.09-.35.45-.56.8-.47z" />
      <path d="m75.02 46.93h.49c.36 0 .65.28.65.64 0 8.18-4.54 18.47-14.79 23.78-6.73 3.48-13.8 4.17-21.04 1.85s-12.31-7.27-15.97-13.93l-1.07 3.99c-.09.35-.45.55-.79.46l-.52-.14c-.35-.09-.55-.46-.46-.8.28-1.03.55-2.03.81-3.03.3-1.11.58-2.22.89-3.33.17-.61.59-.9 1.17-.75 2.14.55 4.27 1.13 6.46 1.72.34.09.55.45.46.79l-.14.52c-.09.35-.45.56-.8.47l-4.57-1.22c1.66 3.32 3.76 6.12 6.42 8.49 3.89 3.46 8.8 5.67 13.98 6.11 7.59.65 14.96-1.98 20.37-7.23 5.01-4.86 7.5-10.86 7.79-17.77.01-.35.29-.62.64-.62z" />
    </g>
  </BaseIcon>
))
Wechselkurs.displayName = 'Wechselkurs'
export default Wechselkurs
