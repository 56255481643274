import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Service = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m71.38 33.06h-.96c-3.18-8.4-11.21-14-20.22-14s-17.04 5.6-20.22 14h-.37c-1.97 0-3.56 1.6-3.56 3.56v9.34c0 1.97 1.6 3.56 3.56 3.56h2.68c1.29 0 2.41-.69 3.03-1.72 1.71 3.9 4.91 6.99 8.87 8.57-7.53 2.12-16.51 8.7-17.2 24.3-.04.8.26 1.58.81 2.16s1.3.9 2.09.9h40.95c.8 0 1.54-.32 2.1-.9.55-.58.85-1.37.81-2.17-.8-16.84-11.05-22.37-17.79-24.2 4.31-1.64 7.73-5.05 9.39-9.34.49 1.39 1.8 2.39 3.35 2.39h2.68c1.97 0 3.56-1.6 3.56-3.56v-9.34c0-1.97-1.6-3.56-3.56-3.56zm-21.18-12c7.94 0 15.04 4.78 18.1 12.02-1.37.16-2.5 1.1-2.95 2.36-2.36-6.08-8.25-10.4-15.15-10.4s-12.37 4.01-14.88 9.74c-.63-1.03-1.75-1.72-3.03-1.72h-.17c3.06-7.23 10.16-12 18.09-12zm-16.35 24.9c0 .86-.7 1.56-1.56 1.56h-2.68c-.86 0-1.56-.7-1.56-1.56v-9.34c0-.86.7-1.56 1.56-1.56h2.68c.86 0 1.56.7 1.56 1.56zm37.89 34.81c.01.26-.08.5-.26.69-.17.18-.4.28-.65.28h-40.94c-.25 0-.48-.1-.65-.28-.18-.19-.27-.43-.26-.69 1.02-22.8 19.68-23.23 21.08-23.23h.05.06.02.04c1.87 0 20.43.7 21.5 23.23zm-21.54-25.22c-7.86 0-14.26-6.4-14.26-14.26s6.4-14.26 14.26-14.26 14.26 6.4 14.26 14.26c0 .5-.03 1-.08 1.49-1.09 1.62-3.57 4.23-8.44 4.65-.44-1.09-1.5-1.87-2.75-1.87-1.64 0-2.97 1.33-2.97 2.97s1.33 2.97 2.97 2.97c1.32 0 2.43-.87 2.81-2.07 3.3-.26 5.69-1.4 7.38-2.7-2.14 5.17-7.24 8.82-13.18 8.82zm22.74-9.59c0 .86-.7 1.56-1.56 1.56h-2.68c-.86 0-1.56-.7-1.56-1.56v-9.34c0-.86.7-1.56 1.56-1.56h2.68c.86 0 1.56.7 1.56 1.56z" />
    </BaseIcon>
  ),
)
Service.displayName = 'Service'
export default Service
