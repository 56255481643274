import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Herz = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m49.26 78.44c-.55 0-1.1-.19-1.66-.58-5.06-3.53-9.45-6.96-13.44-10.49-3.11-2.75-6.33-5.8-8.9-9.46-4.48-6.42-5.87-13.55-4.12-21.2 1.09-4.75 3.75-8.36 7.92-10.75 3.19-1.83 6.76-2.55 10.59-2.14 3.32.36 6.12 1.65 8.34 3.85.45.45.86.92 1.25 1.39 1.74-2.22 3.9-3.75 6.42-4.54 4.99-1.56 9.78-.99 14.26 1.69 3.79 2.27 6.29 5.73 7.43 10.29.26 1.05.41 2.12.55 3.16.06.46.13.92.2 1.37v.16s-.05 3.42-.05 3.42c-.02.08-.04.16-.04.24-.27 3.94-1.47 7.74-3.58 11.29-2.05 3.45-4.76 6.26-7.15 8.55-4.49 4.31-9.69 8.49-16.37 13.15-.56.39-1.12.59-1.67.59zm-11.66-52.73c-2.71 0-5.24.66-7.54 1.98-3.66 2.1-6.01 5.28-6.96 9.46-1.62 7.08-.34 13.67 3.81 19.61 2.31 3.3 5.1 6.03 8.58 9.11 3.93 3.48 8.26 6.86 13.25 10.35.43.3.61.3 1.04 0 6.59-4.6 11.71-8.71 16.12-12.95 2.29-2.2 4.89-4.88 6.81-8.13 1.95-3.28 3.06-6.78 3.3-10.4.01-.18.05-.35.08-.53v-.07s.01-2.85.01-2.85c-.07-.44-.13-.89-.19-1.34-.14-1.03-.27-2-.51-2.95-1.01-4.03-3.2-7.08-6.52-9.06-3.96-2.37-8.21-2.88-12.63-1.5-2.3.72-4.25 2.18-5.8 4.34l-.46.63-.29.15-.72.43-.46-.68c-.21-.25-.42-.5-.63-.74-.44-.53-.86-1.03-1.31-1.48-1.91-1.9-4.25-2.97-7.14-3.28-.62-.07-1.24-.1-1.85-.1z" />
    </BaseIcon>
  ),
)
Herz.displayName = 'Herz'
export default Herz
