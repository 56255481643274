import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Steuern = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m74.78 68.52-13.42-37.34 5.03-9.47c.42-.79.39-1.71-.06-2.48s-1.26-1.22-2.16-1.22h-27.63c-.88 0-1.68.44-2.14 1.19-.46.74-.51 1.66-.12 2.44l4.71 9.51-13.47 37.48c-1.03 2.86-.57 6.03 1.22 8.48 1.72 2.36 4.48 3.76 7.4 3.76h31.94c2.94 0 5.71-1.42 7.43-3.81l.09-.13c1.76-2.45 2.2-5.6 1.18-8.44zm-38.7-47.75c-.11-.23-.02-.43.02-.5s.18-.24.44-.24h27.63c.26 0 .4.17.44.25s.14.27.01.51l-4.96 9.34h-18.95l-4.63-9.35zm35.9 55.03-.09.13c-1.34 1.86-3.51 2.97-5.8 2.97h-31.94c-2.28 0-4.44-1.1-5.78-2.94-1.4-1.92-1.75-4.4-.95-6.63l13.37-37.21h18.79l13.33 37.08c.8 2.22.45 4.68-.93 6.6z" />
        <path d="m60.81 45.82c-.45-.32-1.07-.22-1.4.23l-17.99 24.92c-.32.45-.22 1.07.23 1.4.18.13.38.19.58.19.31 0 .62-.14.81-.42l17.99-24.92c.32-.45.22-1.07-.23-1.4z" />
        <path d="m48.71 51.46c0-2.77-2.25-5.02-5.02-5.02s-5.02 2.25-5.02 5.02 2.25 5.02 5.02 5.02 5.02-2.25 5.02-5.02zm-8.04 0c0-1.67 1.35-3.02 3.02-3.02s3.02 1.36 3.02 3.02-1.35 3.02-3.02 3.02-3.02-1.36-3.02-3.02z" />
        <path d="m58.43 62.52c-2.77 0-5.02 2.25-5.02 5.02s2.25 5.02 5.02 5.02 5.02-2.25 5.02-5.02-2.25-5.02-5.02-5.02zm0 8.04c-1.67 0-3.02-1.36-3.02-3.02s1.35-3.02 3.02-3.02 3.02 1.36 3.02 3.02-1.35 3.02-3.02 3.02z" />
      </g>
    </BaseIcon>
  ),
)
Steuern.displayName = 'Steuern'
export default Steuern
