import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Online_Banking = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m80.52 69.14h-60.68c-2.76 0-5-2.24-5-5v-35.1c0-2.75 2.24-5 5-5h60.68c2.76 0 5 2.24 5 5v35.1c0 2.76-2.24 5-5 5zm-60.68-43.09c-1.65 0-3 1.34-3 3v35.1c0 1.65 1.34 3 3 3h60.68c1.65 0 3-1.34 3-3v-35.11c0-1.65-1.34-3-3-3h-60.68z" />
      <path d="m54.17 30.68h-7.98c-.55 0-1-.45-1-1s.45-1 1-1h7.98c.55 0 1 .45 1 1s-.45 1-1 1z" />
      <path d="m84.52 73.67h-68.68c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h68.67c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
      <path d="m50.08 61.44c-6.88 0-12.47-5.6-12.47-12.47s5.6-12.47 12.47-12.47 12.47 5.6 12.47 12.47-5.6 12.47-12.47 12.47zm0-22.99c-5.8 0-10.51 4.72-10.51 10.51s4.72 10.51 10.51 10.51 10.51-4.72 10.51-10.51-4.72-10.51-10.51-10.51z" />
      <path d="m50.39 55.36c-2.68 0-5.1-1.6-6.16-4.07l-1.22-.02c-.53 0-.96-.43-.96-.96s.43-.96.96-.96h.69c0-.25 0-.47 0-.69v-.17s-.02-.09-.02-.09h-.74c-.53 0-.96-.43-.96-.96s.43-.96.96-.96h1.11c.97-2.71 3.51-4.51 6.35-4.51 1.42 0 2.78.44 3.93 1.27.43.31.53.91.22 1.34-.18.25-.47.4-.78.4-.2 0-.4-.06-.56-.18-.82-.59-1.79-.9-2.8-.9-1.75 0-3.36.97-4.2 2.54l3.49.04c.53 0 .96.43.96.96s-.43.96-.96.96h-4.02l-.04.13s-.01.09-.01.13c0 .2.03.38.05.57v.03s.04.09.04.09h3.9c.53 0 .96.43.96.96s-.43.96-.96.96h-3.16c.87 1.37 2.35 2.16 3.94 2.16 1.03 0 2.02-.33 2.85-.94.17-.12.36-.19.57-.19.3 0 .59.15.78.39.15.21.22.46.18.71s-.17.48-.38.63c-1.16.86-2.54 1.32-4 1.32z" />
    </g>
  </BaseIcon>
))
Online_Banking.displayName = 'Online_Banking'
export default Online_Banking
