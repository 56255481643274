'use client'
import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from 'react'

import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react'
import { AngleLeft, AngleRight } from 'flowbite-react-icons/outline'
import { Button } from 'flowbite-react'

import { cn } from '@/lib/utils'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

interface CarouselProps {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps

const CarouselContext = createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

export const Carousel = ({
  opts,
  plugins,
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement> & CarouselProps) => {
  const [carouselRef, api] = useEmblaCarousel(opts, plugins)
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(false)

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) {
      return
    }

    setCanScrollPrev(api.canScrollPrev())
    setCanScrollNext(api.canScrollNext())
  }, [])

  const scrollPrev = useCallback(() => {
    api?.scrollPrev()
  }, [api])

  const scrollNext = useCallback(() => {
    api?.scrollNext()
  }, [api])

  useEffect(() => {
    if (!api) {
      return
    }

    onSelect(api)
    api.on('reInit', onSelect)
    api.on('select', onSelect)

    return () => {
      api?.off('select', onSelect)
    }
  }, [api, onSelect])

  const settings = useMemo(() => {
    return {
      carouselRef,
      api: api,
      opts,
      scrollPrev,
      scrollNext,
      canScrollPrev,
      canScrollNext,
    }
  }, [
    carouselRef,
    api,
    opts,
    scrollPrev,
    scrollNext,
    canScrollPrev,
    canScrollNext,
  ])

  return (
    <CarouselContext.Provider value={settings}>
      <div
        className={cn('relative', className)}
        aria-roledescription="carousel"
      >
        {children}
      </div>
    </CarouselContext.Provider>
  )
}

export const CarouselContent: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className, children }) => {
  const { carouselRef } = useCarousel()

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div className={cn('flex', className)}>{children}</div>
    </div>
  )
}

export const CarouselItem: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
}) => {
  return (
    <div
      aria-roledescription="slide"
      className={cn('min-w-0 shrink-0 grow-0 basis-full', className)}
    >
      {children}
    </div>
  )
}

export const CarouselNavigation = ({
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'mx-auto flex max-w-sm items-center justify-center',
        className,
      )}
    >
      {children}
    </div>
  )
}

export const CarouselPrevious = ({
  className,
  color = 'dark',
  size = 'lg',
}: React.ComponentProps<typeof Button> & { className?: string }) => {
  const { scrollPrev, canScrollPrev } = useCarousel()

  return (
    <Button
      color={color}
      size={size}
      className={cn('mr-6 size-8 rounded-full', className)}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
    >
      <AngleLeft className="size-7" />
    </Button>
  )
}

export const CarouselNext = ({
  className,
  color = 'dark',
  size = 'lg',
}: React.ComponentProps<typeof Button> & {
  className?: string
}) => {
  const { scrollNext, canScrollNext } = useCarousel()

  return (
    <Button
      color={color}
      size={size}
      className={cn('ml-6 size-8 rounded-full', className)}
      disabled={!canScrollNext}
      onClick={scrollNext}
    >
      <AngleRight className="size-7" />
    </Button>
  )
}

export const CarouselIndicators = ({ className }: { className?: string }) => {
  const { api } = useCarousel()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!api) return
      api.scrollTo(index)
    },
    [api],
  )

  const onInit = useCallback((api: CarouselApi) => {
    if (!api) return
    setScrollSnaps(api.scrollSnapList())
  }, [])

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) return
    setSelectedIndex(api.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!api) return

    onInit(api)
    onSelect(api)
    api.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [api, onInit, onSelect])

  return (
    <>
      <div className="xl:hidden">
        {selectedIndex + 1} / {scrollSnaps.length}
      </div>
      {scrollSnaps.map((_, index) => (
        <button
          key={index}
          onClick={() => onDotButtonClick(index)}
          className={cn(
            'mx-1.5 hidden size-3 rounded-full xl:inline-block',
            selectedIndex === index ? 'bg-gray-800' : 'bg-gray-400',
            className,
          )}
        ></button>
      ))}
    </>
  )
}
