import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Stoerung = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m49.49 23c-14.34 0-26 11.66-26 26s11.66 26 26 26 26-11.66 26-26-11.66-26-26-26zm0 50c-13.23 0-24-10.77-24-24s10.77-24 24-24 24 10.77 24 24-10.77 24-24 24z" />
        <path d="m59.04 60.07c-.4 0-.78-.16-1.06-.44l-8.49-8.5-8.51 8.5c-.28.28-.66.44-1.06.44s-.78-.16-1.06-.44-.44-.66-.44-1.06.16-.78.44-1.06l8.51-8.5-8.51-8.52c-.28-.28-.44-.66-.44-1.06s.16-.78.44-1.06.66-.44 1.06-.44.78.16 1.06.44l8.51 8.52 8.51-8.5c.28-.28.66-.44 1.06-.44s.78.16 1.06.44.44.66.44 1.06-.16.78-.44 1.06l-8.51 8.5 8.49 8.5c.28.28.44.66.44 1.06s-.16.78-.44 1.06-.66.44-1.06.44z" />
      </g>
    </BaseIcon>
  ),
)
Stoerung.displayName = 'Stoerung'
export default Stoerung
