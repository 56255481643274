import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Info = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m50 75c-14.34 0-26-11.66-26-26s11.66-26 26-26 26 11.66 26 26-11.66 26-26 26zm0-50c-13.23 0-24 10.77-24 24s10.77 24 24 24 24-10.77 24-24-10.77-24-24-24z" />
        <circle cx="50.04" cy="34.05" r="2.41" />
        <path d="m50.04 66.66c-.83 0-1.5-.67-1.5-1.5v-23.44c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v23.44c0 .83-.67 1.5-1.5 1.5z" />
      </g>
    </BaseIcon>
  ),
)
Info.displayName = 'Info'
export default Info
