'use client'

import { useTranslations } from 'next-intl'
import Script from 'next/script'

export const AccessibilitySettings = () => {
  const t = useTranslations('AccessibilitySettings')
  const clickHandler = () => {
    const accessWidgets = document.querySelectorAll('access-widget-ui')
    let targetButton: HTMLButtonElement | null = null

    for (const widget of accessWidgets) {
      if (widget.shadowRoot) {
        const button = widget.shadowRoot.querySelector<HTMLButtonElement>(
          '[part="acsb-trigger"]',
        )
        if (button) {
          targetButton = button
          break
        }
      }
    }
    if (targetButton) {
      targetButton.click()
    }
  }
  return (
    <button
      className="cursor-pointer underline lg:no-underline"
      onClick={clickHandler}
    >
      {t('label')}
    </button>
  )
}

declare const window:
  | ({
      acsbJS?: {
        init: (k: {
          statementLink?: string
          footerHtml?: string
          hideMobile?: boolean
          hideTrigger?: boolean
          language?: string
          position?: 'left' | 'right'
          leadColor?: string
          triggerColor?: string
          triggerRadius?: string
          triggerPositionX?: 'left' | 'right'
          triggerPositionY?: 'top' | 'bottom'
          triggerIcon?: string
          triggerSize?: 'small' | 'medium' | 'large'
          triggerOffsetX?: number
          triggerOffsetY?: number
          mobile?: {
            triggerSize?: 'small' | 'medium' | 'large'
            triggerPositionX?: 'left' | 'right'
            triggerPositionY?: 'top' | 'bottom'
            triggerOffsetX?: number
            triggerOffsetY?: number
            triggerRadius?: string
          }
        }) => void
      }
    } & Window)
  | undefined

export const AccessibilityScript = ({ locale }: { locale: string }) => {
  return (
    <Script
      src="https://eu.acsbapp.com/apps/app/dist/js/app.js"
      onLoad={() => {
        if (
          typeof window !== 'undefined' &&
          'acsbJS' in window &&
          typeof window.acsbJS === 'object'
        ) {
          window.acsbJS.init({
            statementLink: '',
            footerHtml: ' ',
            hideMobile: false,
            hideTrigger: true,
            language: locale == 'de' ? locale : 'gb',
            position: 'left',
            leadColor: '#005aa5',
          })
        }
      }}
    />
  )
}
