import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Umwelt = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m75.46 34.15s-.05-.07-.08-.11c-5.1-7.45-13.66-12.35-23.35-12.35-15.59 0-28.27 12.68-28.27 28.27s12.68 28.27 28.27 28.27 28.27-12.68 28.27-28.27c0-5.85-1.79-11.3-4.85-15.81zm-2.51-.07-.79.2c-.98.25-1.91.63-2.79 1.13l-2.84 1.63c-1.02.58-2.13.95-3.3 1.08l-2.34.26c-.44.05-.88-.03-1.28-.22l-.52-.26c-.61-.3-.88-1.02-.62-1.65l.17-.4c.29-.71.4-1.49.29-2.26l-.78-5.82-.24-3.4c6.09 1.39 11.38 4.9 15.05 9.72zm-36.67 13.32c-.6-.65-1.48-.94-2.34-.76l-1.29.26c-.89.18-1.8.19-2.7.01l-3.9-.75c.63-4.33 2.32-8.31 4.79-11.69l4.01-.15c.77-.03 1.55-.1 2.32-.21l4.4-.65c.58-.08 1.17.12 1.57.55l2.61 2.82c.92 1 1.35 2.35 1.18 3.7l-2.85 22.34c-.08.59-.54 1.05-1.13 1.13l-.45.06c-.75.08-1.5-.34-1.79-1.04l-1.46-3.5s-.04-.09-.06-.13l-3-5.02c-.29-.48-.36-1.07-.2-1.61l.87-2.96c.25-.85.04-1.74-.56-2.4zm-10.51 2.56c0-.6.03-1.2.07-1.8l3.73.72c1.16.22 2.33.21 3.48-.02l1.29-.26c.25-.05.41.09.47.15s.18.23.11.48l-.87 2.96c-.32 1.08-.17 2.24.41 3.2l2.96 4.95 1.43 3.43c.58 1.39 1.95 2.29 3.43 2.29.15 0 .3 0 .45-.03l.45-.06c1.5-.18 2.68-1.36 2.87-2.86l2.85-22.34c.25-1.94-.37-3.87-1.7-5.31l-2.61-2.82c-.85-.92-2.1-1.36-3.33-1.17l-4.4.65c-.69.1-1.4.17-2.1.19l-2.27.08c4.81-5.35 11.79-8.73 19.53-8.73 1.3 0 2.58.1 3.83.28l.29 3.99.78 5.88c.06.42 0 .84-.16 1.23l-.17.4c-.66 1.6.04 3.45 1.59 4.21l.52.26c.59.29 1.24.44 1.9.44.16 0 .32 0 .49-.03l2.34-.26c1.44-.16 2.81-.61 4.07-1.33l2.84-1.63c.72-.41 1.49-.72 2.29-.92l1.52-.39c1.88 2.93 3.19 6.25 3.78 9.81l-12.59-.91c-1.63-.13-3.27.46-4.47 1.58l-2.5 2.33c-1.27 1.19-1.75 3.03-1.22 4.69l.68 2.12c.37 1.15 1.21 2.08 2.3 2.58l3.26 1.46c.99.44 1.77 1.28 2.14 2.3l3.08 8.51c-4.53 3.7-10.31 5.93-16.6 5.93-14.49 0-26.27-11.79-26.27-26.27zm44.48 18.91-2.81-7.76c-.55-1.53-1.72-2.78-3.2-3.45l-3.26-1.46c-.58-.26-1.02-.76-1.22-1.36l-.68-2.12c-.3-.93-.03-1.95.68-2.62l2.5-2.34c.79-.74 1.87-1.12 2.96-1.04l12.99.94c.07.76.11 1.52.11 2.29 0 7.42-3.1 14.13-8.06 18.91z" />
    </BaseIcon>
  ),
)
Umwelt.displayName = 'Umwelt'
export default Umwelt
