import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const EMail = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m79.97 67.71h-59.94c-2.85 0-5.18-2.32-5.18-5.18v-33.12c0-.96.28-1.91.8-2.74.96-1.53 2.59-2.44 4.38-2.44h59.93c1.78 0 3.42.91 4.38 2.43t0 0c.52.83.8 1.78.8 2.74v33.13c0 2.85-2.32 5.18-5.18 5.18zm-63.05-38.93c-.04.21-.06.41-.06.62v33.13c0 1.75 1.42 3.18 3.18 3.18h59.93c1.75 0 3.18-1.42 3.18-3.18v-33.12c0-.21-.02-.42-.07-.62l-32.47 25.05c-.18.14-.4.21-.61.21-.21 0-.43-.07-.61-.21zm1.01-1.74 32.07 24.74 32.07-24.74c-.57-.51-1.31-.8-2.1-.8h-59.94c-.79 0-1.53.29-2.1.81z" />
    </BaseIcon>
  ),
)
EMail.displayName = 'EMail'
export default EMail
