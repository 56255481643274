import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Bankschalter = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <path d="m83.05 47.7h-5.84c0-.07.01-.13.01-.2-.36-7.47-4.43-10.52-7.71-11.77 2.48-1.45 4.15-4.14 4.15-7.21 0-4.61-3.75-8.36-8.36-8.36s-8.36 3.75-8.36 8.36c0 3.1 1.7 5.8 4.2 7.24-3.22 1.26-7.18 4.31-7.51 11.74 0 .07 0 .13.01.19h-16.4v-4.29h9.27c2.76 0 5-2.24 5-5v-13.68c0-2.75-2.24-5-5-5h-26.56c-2.75 0-5 2.24-5 5v13.68c0 2.75 2.24 5 5 5h9.27v4.29h-11.9c-1.3 0-2.37 1.06-2.37 2.37v3.11c0 1.3 1.06 2.37 2.37 2.37h.08c-.04.19-.07.38-.07.57v22.36c0 1.42 1.16 2.57 2.57 2.57h60.78c1.42 0 2.57-1.15 2.57-2.57v-22.36c0-.2-.03-.4-.07-.59 1.24-.07 2.24-1.09 2.24-2.35v-3.11c0-1.3-1.06-2.37-2.37-2.37zm-24.11-19.18c0-3.51 2.85-6.36 6.36-6.36s6.36 2.85 6.36 6.36-2.85 6.36-6.36 6.36-6.36-2.85-6.36-6.36zm6.37 8.36c.4 0 9.36.05 9.91 10.64h-19.59c.51-10.59 9.34-10.64 9.68-10.64zm-48.36 1.53v-13.68c0-1.65 1.34-3 3-3h26.56c1.65 0 3 1.34 3 3v13.68c0 1.65-1.34 3-3 3h-26.56c-1.65 0-3-1.34-3-3zm14.27 5.28h4.01v4.01h-4.01zm50.03 34.78c0 .32-.26.57-.57.57h-60.78c-.32 0-.57-.26-.57-.57v-22.36c0-.32.26-.57.57-.57h60.78c.32 0 .57.26.57.57zm2.16-25.3c0 .2-.16.37-.37.37h-65.72c-.2 0-.37-.16-.37-.37v-3.11c0-.2.16-.37.37-.37h65.73c.2 0 .37.16.37.37v3.11z" />
  </BaseIcon>
))
Bankschalter.displayName = 'Bankschalter'
export default Bankschalter
