import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Wertanstieg = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <path d="m79.31 30.78s0-.08-.01-.13c0-.03 0-.06 0-.09-.02-.12-.05-.23-.1-.34 0 0 0 0 0-.01s-.02-.02-.02-.04c-.05-.09-.1-.18-.17-.26-.02-.02-.04-.05-.06-.07-.08-.09-.16-.16-.26-.23-.01 0-.03-.01-.04-.02-.09-.05-.18-.1-.28-.13-.03 0-.06-.02-.09-.03-.11-.03-.23-.05-.36-.05h-14.01c-.77 0-1.4.63-1.4 1.4s.63 1.4 1.4 1.4h10.89l-23.84 26.78-11.34-12.18c-.26-.28-.64-.45-1.03-.45s-.77.17-1.03.46l-19.65 21.6c-.52.57-.48 1.46.09 1.98.26.23.59.36.94.36.39 0 .77-.17 1.04-.46l18.62-20.48 11.36 12.19c.23.25.56.39 1.04.45.39 0 .77-.17 1.03-.47l24.49-27.51v11.72c0 .77.63 1.4 1.4 1.4s1.4-.63 1.4-1.4v-15.4z" />
  </BaseIcon>
))
Wertanstieg.displayName = 'Wertanstieg'
export default Wertanstieg
