import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Staaten = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m76.43 30.55h-8.88v-7.31c0-2.38-1.49-4.25-3.39-4.25h-30.2c-1.9 0-3.39 1.87-3.39 4.25v18.82s0 .02 0 .03v29.26c0 .55.45 1 1 1s1-.45 1-1v-25.4c.42.23.89.37 1.38.37h8.88v7.31s0 .02 0 .03v29.25c0 .55.45 1 1 1s1-.45 1-1v-25.4c.42.23.89.37 1.38.37h30.2c1.9 0 3.39-1.87 3.39-4.25v-18.82c0-2.38-1.49-4.25-3.39-4.25zm-43.86 11.55v-4.75s0 0 0 0v-14.09c0-1.33.73-2.25 1.39-2.25h30.2c.66 0 1.39.92 1.39 2.25v18.82c0 1.33-.73 2.25-1.39 2.25h-30.2c-.65 0-1.37-.91-1.38-2.22zm45.25 11.53c0 1.33-.73 2.25-1.39 2.25h-30.2c-.65 0-1.37-.91-1.38-2.21v-4.76s0 0 0-.01v-2.57h19.32c1.9 0 3.39-1.87 3.39-4.25v-9.51h8.88c.66 0 1.39.92 1.39 2.25v18.82z" />
    </BaseIcon>
  ),
)
Staaten.displayName = 'Staaten'
export default Staaten
