import { Textarea as UITextarea } from 'flowbite-react'
import { useFormStatus } from 'react-dom'
import { Fieldset, FieldsetLabel } from '../Fieldset'
import type { FormTextareaFieldFragment } from '../'
import { FragmentOf } from '@/graphql'

export const Textarea = ({
  name,
  label,
  required,
  width,
  id,
  placeholder,
  defaultValue,
}: FragmentOf<typeof FormTextareaFieldFragment> & { defaultValue: string }) => {
  const { pending } = useFormStatus()
  return (
    <Fieldset width={width}>
      <FieldsetLabel {...{ id, required }}>{label}</FieldsetLabel>
      <UITextarea
        name={name}
        id={id!}
        disabled={pending}
        required={!!required}
        placeholder={placeholder ?? ''}
        defaultValue={defaultValue}
        className="min-h-32"
      />
    </Fieldset>
  )
}
