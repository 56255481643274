import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Vertrag = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m47.12 79.41c-.38 0-.74-.22-.91-.58l-23.16-49.34c-.12-.27-.13-.57 0-.84.12-.27.36-.47.64-.55l31.44-8.87c.46-.13.95.09 1.17.52l23.72 47.68c.13.26.14.56.03.83s-.33.47-.61.56l-32 10.54c-.1.03-.21.05-.31.05zm-21.76-49.7 22.29 47.48 30.06-9.9-22.84-45.9z" />
        <path d="m58.57 71.03c-.34 0-.67-.17-.85-.48-.29-.47-.14-1.09.33-1.37s2.52-1.58 3.07-3.81c-2.02-1.46-2-2.69-1.86-3.22.19-.72.86-1.2 1.63-1.16.88.05 2 .79 2.28 2.71.03.22.05.43.06.63.18.1.37.2.58.31 1.51.75 2.14.83 2.31.82.03-.09.07-.24.09-.34.12-.48.29-1.2 1.01-1.6.75-.42 1.66-.19 2.28.01 2.75.92 3.52-.63 3.6-.81.22-.5.81-.74 1.31-.53s.75.78.54 1.29c-.49 1.21-2.38 3.18-6.08 1.95-.33-.11-.53-.14-.63-.14-.03.09-.06.21-.08.3-.12.49-.31 1.3-1.14 1.68-.88.41-2.07.17-4.09-.84 0 0-.02 0-.03-.01-.96 2.7-3.66 4.37-3.8 4.46-.16.1-.34.15-.52.15z" />
      </g>
    </BaseIcon>
  ),
)
Vertrag.displayName = 'Vertrag'
export default Vertrag
