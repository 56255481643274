import { type FlowbiteIconProps } from 'flowbite-react-icons'

import * as Outline from 'flowbite-react-icons/outline'
import * as Solid from 'flowbite-react-icons/solid'
import * as Custom from './Custom'

type IconComponent = React.ForwardRefExoticComponent<
  FlowbiteIconProps & React.RefAttributes<SVGSVGElement>
>

export interface IconSets {
  Outline: typeof Outline
  Solid: typeof Solid
  Custom: typeof Custom
}

const Icons = {
  Outline,
  Solid,
  Custom,
}

export const getIcon = (
  iconSet: keyof IconSets,
  iconName: string,
): IconComponent | undefined => {
  const set = Icons[iconSet]
  return set ? set[iconName as keyof typeof set] : undefined
}

export { Outline, Solid, Custom }
