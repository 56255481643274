import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Vimeo = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 48 48" {...props}>
      <path
        fillRule="evenodd"
        d="M27.2472 15.3353C32.2576 12.3268 34.958 16.5672 32.3896 21.3604C29.8156 26.1728 27.4535 29.3105 26.2297 29.3105C25.028 29.3105 24.0765 26.082 22.6686 20.4777C21.2248 14.6725 21.2248 4.20626 15.186 5.39423C9.47709 6.52171 2 15.459 2 15.459L3.77646 17.7992C3.77646 17.7992 7.46138 14.8925 8.68786 16.339C9.93083 17.7992 14.636 35.3328 16.1924 38.575C17.5509 41.4157 21.3211 45.1639 25.4405 42.4909C29.5984 39.8015 43.3234 28.0812 45.7818 14.2133C48.2347 0.370088 29.2382 3.26853 27.2472 15.3353Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  ),
)
Vimeo.displayName = 'Vimeo'
export default Vimeo
