import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Kalender = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m83.87 20.41h-4.88v-7.41c0-.55-.45-1-1-1s-1 .45-1 1v7.41h-51.98v-7.41c0-.55-.45-1-1-1s-1 .45-1 1v7.41h-4.75c-2.76 0-5 2.24-5 5v44.31c0 2.76 2.24 5 5 5h65.62c2.76 0 5-2.24 5-5v-44.31c0-2.76-2.24-5-5-5zm3 49.31c0 1.65-1.34 3-3 3h-65.61c-1.65 0-3-1.34-3-3v-44.31c0-1.65 1.34-3 3-3h4.75v6.74c0 .55.45 1 1 1s1-.45 1-1v-6.74h51.98v6.74c0 .55.45 1 1 1s1-.45 1-1v-6.74h4.88c1.65 0 3 1.34 3 3z" />
        <path d="m33.21 41.67h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m33.21 50.42h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m33.21 59.17h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m55.66 41.67h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m55.66 50.42h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m55.66 59.17h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m77.99 41.67h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m77.99 50.42h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
        <path d="m77.99 59.17h-9.2c-.55 0-1 .45-1 1s.45 1 1 1h9.2c.55 0 1-.45 1-1s-.45-1-1-1z" />
      </g>
    </BaseIcon>
  ),
)
Kalender.displayName = 'Kalender'
export default Kalender
