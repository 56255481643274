import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Handy = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m61.2 77.06h-22.4c-2.75 0-5-2.24-5-5v-46.12c0-2.76 2.24-5 5-5h22.4c2.75 0 5 2.24 5 5v46.12c0 2.76-2.24 5-5 5zm-22.4-54.12c-1.65 0-3 1.34-3 3v46.12c0 1.65 1.34 3 3 3h22.4c1.65 0 3-1.34 3-3v-46.12c0-1.65-1.34-3-3-3z" />
        <path d="m53.75 28.25h-7.5c-.55 0-1-.45-1-1s.45-1 1-1h7.5c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <circle cx="50" cy="70.06" r="2.2" />
      </g>
    </BaseIcon>
  ),
)
Handy.displayName = 'Handy'
export default Handy
