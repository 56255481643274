import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Bank = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m80.05 74.95h-4.84v-28.49c1.88-.02 3.41-1.56 3.41-3.45v-.49c0-.51-.12-.98-.31-1.42h1.53c1.82 0 3.34-1.1 3.8-2.73.43-1.54-.23-3.09-1.66-3.95l-28.2-16.98c-1.08-.65-2.33-.99-3.62-.99s-2.54.34-3.62.99l-28.21 16.98c-1.44.86-2.09 2.42-1.66 3.95.46 1.63 1.98 2.73 3.8 2.73h1.44c-.3.51-.48 1.09-.48 1.72v.49c0 1.88 1.52 3.42 3.4 3.44v28.18h-4.88c-2.05 0-3.72 1.67-3.72 3.72v.54c0 2.05 1.67 3.72 3.72 3.72h60.1c2.05 0 3.72-1.67 3.72-3.72v-.54c0-2.05-1.67-3.72-3.72-3.72zm-13.11 0v-28.49h6.25v28.49zm-23.45-32.42c0-.76.59-1.37 1.34-1.42h10.19c.74.05 1.34.66 1.34 1.42v.49c0 .79-.64 1.42-1.42 1.42h-10.02c-.79 0-1.42-.64-1.42-1.42v-.49zm3.43 3.94h6.43v28.49h-6.43zm8.46-.05c1.69-.22 3-1.66 3-3.4v-.49c0-.51-.12-.98-.31-1.42h3.96c-.2.43-.31.91-.31 1.42v.49c0 1.82 1.42 3.3 3.2 3.42v28.51h-9.54zm21.22-3.89v.49c0 .79-.64 1.42-1.42 1.42h-10.02c-.79 0-1.42-.64-1.42-1.42v-.49c0-.76.59-1.37 1.34-1.42h10.19c.74.05 1.34.66 1.34 1.42zm-57.98-4.69c-.18-.66.1-1.28.75-1.68l28.21-16.98c.77-.46 1.66-.71 2.58-.71s1.81.24 2.58.71l28.2 16.98c.66.39.94 1.02.76 1.68-.16.58-.74 1.25-1.85 1.25h-4.58s-.05 0-.08 0h-10.02s-.05 0-.08 0h-10.07s-.05 0-.08 0h-10.02s-.05 0-.08 0h-24.37c-1.11 0-1.69-.67-1.85-1.25zm23.16 3.28c-.2.43-.31.91-.31 1.42v.49c0 1.9 1.54 3.44 3.43 3.45v28.49h-9.58v-28.22c1.7-.21 3.03-1.65 3.03-3.41v-.49c0-.63-.18-1.21-.48-1.72h3.91zm-18.33 2.21v-.49c0-.79.64-1.42 1.42-1.42h10.02c.79 0 1.42.64 1.42 1.42v.49c0 .79-.64 1.42-1.42 1.42h-10.02c-.79 0-1.42-.64-1.42-1.42zm3.4 3.44h6.44v28.18h-6.44zm54.9 32.44c0 .93-.76 1.69-1.69 1.69h-60.11c-.93 0-1.69-.76-1.69-1.69v-.54c0-.93.76-1.69 1.69-1.69h60.1c.93 0 1.69.76 1.69 1.69v.54z" />
    </BaseIcon>
  ),
)
Bank.displayName = 'Bank'
export default Bank
