import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Ansprechpartner = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m81 13.69h-34c-3.24 0-5.88 2.64-5.88 5.88v5.4c-.34-.02-.67-.05-1.02-.05-8.96 0-16.26 7.29-16.26 16.26 0 6.86 4.28 12.73 10.3 15.11-7.54 2.13-16.47 8.69-17.16 24.28-.04.8.26 1.58.81 2.16s1.3.9 2.09.9h40.95c.8 0 1.54-.32 2.1-.9.55-.58.85-1.37.81-2.17-.8-16.86-11.08-22.4-17.82-24.23 3.96-1.53 7.18-4.56 8.94-8.4h10.04v3.34c0 .87.48 1.64 1.27 2.02.31.15.65.23.98.23.49 0 .98-.17 1.39-.49l6.42-5.1h6.03c3.24 0 5.88-2.64 5.88-5.88v-22.49c0-3.24-2.64-5.88-5.88-5.88zm-40.88 43.74c.84 0 20.52.06 21.63 23.22.01.26-.08.5-.26.69-.17.18-.4.28-.65.28h-40.94c-.25 0-.48-.1-.65-.28-.18-.19-.27-.43-.26-.69 1.02-22.8 19.68-23.23 21.08-23.23h.04zm-.01-2c-7.86 0-14.26-6.4-14.26-14.26s6.4-14.26 14.26-14.26 14.26 6.4 14.26 14.26-6.4 14.26-14.26 14.26zm44.77-13.37c0 2.14-1.74 3.88-3.88 3.88h-6.38c-.23 0-.44.08-.62.22l-6.69 5.31c-.1.08-.19.06-.25.03s-.14-.09-.14-.22v-4.34c0-.55-.45-1-1-1h-10.27c.46-1.51.71-3.1.71-4.76 0-7.93-5.71-14.55-13.24-15.96v-5.64c0-2.14 1.74-3.88 3.88-3.88h34c2.14 0 3.88 1.74 3.88 3.88v22.49z" />
      <path d="m66.47 26.44c-.37-.34-.77-.52-1.21-.52s-.8.14-1.09.42-.43.64-.43 1.08c0 .29.05.54.15.75s.26.45.48.73c.26.35.46.67.59.97.13.29.2.67.2 1.14 0 .91-.16 1.68-.48 2.31s-.67 1.11-1.03 1.43v.31l.77.7h.02c.45-.25.93-.67 1.43-1.26s.92-1.32 1.26-2.18.52-1.77.52-2.73c0-.63-.11-1.22-.32-1.78s-.5-1.01-.87-1.35z" />
      <path d="m72.56 26.44c-.37-.34-.78-.52-1.22-.52s-.78.14-1.07.42-.43.64-.43 1.08c0 .29.05.54.15.75s.26.45.48.73c.26.35.46.67.59.97.13.29.2.67.2 1.14 0 .91-.16 1.67-.48 2.3-.32.62-.67 1.1-1.03 1.44v.31l.77.7h.02c.45-.25.93-.67 1.43-1.26s.92-1.32 1.26-2.18.52-1.77.52-2.73c0-.63-.11-1.22-.32-1.78s-.51-1.01-.88-1.35z" />
    </g>
  </BaseIcon>
))
Ansprechpartner.displayName = 'Ansprechpartner'
export default Ansprechpartner
