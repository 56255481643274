'use client'

import { useState } from 'react'
// import Link from 'next/link'
import { ChevronDown, ChevronUp } from 'flowbite-react-icons/outline'
import { cn } from '@/lib/utils'

export const Level2Link = (props: {
  href: string
  children?: React.ReactNode
  className?: string
  renderLabel: React.ReactNode
  initialShowMegaMenu?: boolean
}) => {
  const {
    href,
    children,
    className,
    renderLabel,
    initialShowMegaMenu = false,
  } = props
  const [showMegaMenu, setShowMegaMenu] = useState(initialShowMegaMenu)
  const Chevron = showMegaMenu ? ChevronUp : ChevronDown
  /*
   * The menu behavior considers breakpoints, touch support and dropdown opening state.
   * It especially needs to make the level 2 link accessible which also acts as a trigger for the dropdown.
   *
   * 1. On small screens, a button is shown next to the level 2 link label to toggle the dropdown.
   * 2. On large screens (:lg), touch devices (:coarse) show the dropdown on first tab on the level 2 link. Another tab follows the link.
   * 3. On large screens with mouse input (:fine), the dropdown shows on hover. Clicking the level 2 link always follows the link.
   */
  return (
    <li className="group" tabIndex={0}>
      <a
        className={cn(
          className,
          'relative z-50 bg-white lg:bg-transparent',
          // pass focus state to parent group on first tab on touch devices
          'lg:coarse:pointer-events-none',
          // enable pointer events again for second tab (after parent group has received focus)
          'lg:coarse:group-focus-within:pointer-events-auto',
        )}
        {...{ href }}
      >
        {renderLabel}
        <button
          className="flex grow justify-end lg:hidden"
          onClick={(e) => {
            e.preventDefault()
            setShowMegaMenu(!showMegaMenu)
          }}
        >
          <Chevron className="pointer-events-none ml-1 size-8 text-gray" />
        </button>
      </a>
      {children && (
        <div
          className={cn(
            'left-0 top-full z-40 -translate-y-10 overflow-hidden transition-all duration-200',
            // show the dropdown on small screens when toggled via button
            {
              'max-h-0 opacity-0': !showMegaMenu,
              'max-h-[9999px] translate-y-0 opacity-100': showMegaMenu,
            },
            // reset showMegaMenu state rules on larger screens
            'lg:invisible lg:max-h-[9999px] lg:-translate-y-10 lg:opacity-0',
            // show the dropdown on first tab on touch devices
            'lg:coarse:group-focus-within:visible lg:coarse:group-focus-within:translate-y-0 lg:coarse:group-focus-within:opacity-100',
            // show the dropdown on hover on mouse input
            'lg:fine:group-hover:visible lg:fine:group-hover:translate-y-0 lg:fine:group-hover:opacity-100',
            // position the dropdown on larger screens
            'lg:absolute lg:max-w-[64rem] lg:pt-7 lg:delay-200',
            // center the dropdown on larger screens
            'xl:left-1/2 xl:min-w-max xl:-translate-x-1/2',
          )}
        >
          {children}
        </div>
      )}
    </li>
  )
}
