import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Computer = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m87.68 13.68h-75.36c-2.75 0-5 2.24-5 5v44.31c0 2.76 2.24 5 5 5h29.43v10.7h-2.75c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h22c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-2.75v-10.7h29.43c2.75 0 5-2.24 5-5v-44.32c0-2.76-2.24-5-5-5zm-43.93 65v-10.5h12.5v10.5zm46.93-15.7c0 1.65-1.34 3-3 3h-75.36c-1.65 0-3-1.34-3-3v-44.31c0-1.65 1.34-3 3-3h75.37c1.65 0 3 1.34 3 3v44.31z" />
        <path d="m53.99 18.52h-7.98c-.55 0-1 .45-1 1s.45 1 1 1h7.98c.55 0 1-.45 1-1s-.45-1-1-1z" />
      </g>
    </BaseIcon>
  ),
)
Computer.displayName = 'Computer'
export default Computer
