'use client'

// import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { ChevronLeft, ChevronRight } from 'flowbite-react-icons/outline'
import { cn } from '@/lib/utils'

export function Pagination({ totalPages }: Readonly<{ totalPages: number }>) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams)
    params.set('page', pageNumber.toString())
    return `${pathname}?${params.toString()}`
  }

  const allPages = generatePagination(currentPage, totalPages)

  return (
    <nav className="flex justify-center overflow-x-auto">
      <ul className="flex h-8 items-center -space-x-px text-sm">
        <PaginationArrow
          direction="left"
          href={createPageURL(currentPage - 1)}
          isDisabled={currentPage <= 1}
        />
        {allPages.map((page, index) => {
          return (
            <PaginationNumber
              key={`${page}-${index}`}
              href={createPageURL(page)}
              page={page}
              isActive={currentPage === page}
              isMiddle={page === '...'}
            />
          )
        })}
        <PaginationArrow
          direction="right"
          href={createPageURL(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
        />
      </ul>
    </nav>
  )
}

function PaginationNumber({
  page,
  href,
  isActive,
  isMiddle,
}: {
  page: number | string
  href: string
  isActive: boolean
  isMiddle: boolean
}) {
  const className = cn(
    isActive &&
      'z-10 flex h-8 items-center justify-center border px-3 leading-tight',
    'flex h-8 items-center justify-center border px-3 leading-tight',
    {
      'border-gray-300 bg-white text-gray-500': !isActive,
      'hover:bg-gray-100 hover:text-gray-700': !isMiddle,
      'border-blue-300 bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700':
        isActive,
    },
  )

  return isActive || isMiddle ? (
    <div className={className}>{page}</div>
  ) : (
    <a href={href} className={className}>
      {page}
    </a>
  )
}

function PaginationArrow({
  href,
  direction,
  isDisabled,
}: {
  href: string
  direction: 'left' | 'right'
  isDisabled?: boolean
}) {
  const className = cn(
    'flex h-8 items-center justify-center border border-gray-300 bg-white px-1.5 leading-tight',
    {
      'pointer-events-none text-gray-300': isDisabled,
      'text-gray-500 hover:bg-gray-100 hover:text-gray-700': !isDisabled,
      'ms-0 rounded-s-lg border border-e-0': direction === 'left',
      'rounded-e-lg border': direction === 'right',
    },
  )

  const Chevron = direction === 'left' ? <ChevronLeft /> : <ChevronRight />

  return isDisabled ? (
    <div className={className}>{Chevron}</div>
  ) : (
    <a className={className} href={href}>
      {Chevron}
    </a>
  )
}

export const generatePagination = (currentPage: number, totalPages: number) => {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, '...', totalPages - 1, totalPages]
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages]
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [
    1,
    '...',
    currentPage - 1,
    currentPage,
    currentPage + 1,
    '...',
    totalPages,
  ]
}
