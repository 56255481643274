import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Haus = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m45.09 78.52-15.74-.18c-1.91-.02-3.47-1.6-3.47-3.51v-36.23c0-.93.36-1.8 1.01-2.46l21.84-22.23c.66-.68 1.55-1.05 2.5-1.05.95 0 1.84.37 2.5 1.05l21.85 22.26c.65.66 1 1.53 1 2.46v36.36c0 1.93-1.57 3.51-3.51 3.51l-27.99.02zm6.15-63.67c-.41 0-.79.16-1.08.45l-21.84 22.23c-.28.28-.43.66-.43 1.06v36.23c0 .82.67 1.5 1.49 1.51l15.72.18 27.98-.02c.83 0 1.51-.68 1.51-1.51v-36.36c0-.4-.15-.77-.43-1.06l-21.85-22.26c-.29-.29-.67-.45-1.08-.45z" />
        <path d="m65.32 56.73h-12.71c-1.28 0-2.32-1.04-2.32-2.32v-12.69c0-1.28 1.04-2.32 2.32-2.32h12.71c1.28 0 2.32 1.04 2.32 2.32v12.69c0 1.28-1.04 2.32-2.32 2.32zm-12.71-15.33c-.17 0-.32.14-.32.32v12.69c0 .17.14.32.32.32h12.71c.17 0 .32-.14.32-.32v-12.69c0-.17-.14-.32-.32-.32z" />
      </g>
    </BaseIcon>
  ),
)
Haus.displayName = 'Haus'
export default Haus
