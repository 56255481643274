import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const FossileBrennstoffe = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m76.68 25.74v-4.26c0-1.3-1.06-2.37-2.37-2.37h-48.11c-1.3 0-2.37 1.06-2.37 2.37v4.26c0 1.3 1.06 2.37 2.37 2.37h1.13c-.04.19-.07.38-.07.57v44.22c0 .2.03.39.07.57h-1.13c-1.3 0-2.37 1.06-2.37 2.37v4.26c0 1.3 1.06 2.37 2.37 2.37h48.11c1.3 0 2.37-1.06 2.37-2.37v-4.26c0-1.3-1.06-2.37-2.37-2.37h-1.13c.04-.19.07-.38.07-.57v-44.22c0-.2-.03-.39-.07-.57h1.13c1.3 0 2.37-1.06 2.37-2.37zm-50.85 0v-4.26c0-.2.16-.37.37-.37h48.11c.2 0 .37.16.37.37v4.26c0 .2-.16.37-.37.37h-48.11c-.2 0-.37-.16-.37-.37zm48.85 50.11v4.26c0 .2-.16.37-.37.37h-48.11c-.2 0-.37-.16-.37-.37v-4.26c0-.2.16-.37.37-.37h48.11c.2 0 .37.16.37.37zm-3.43-47.17v44.22c0 .32-.26.57-.57.57h-40.85c-.32 0-.57-.26-.57-.57v-44.22c0-.32.26-.57.57-.57h40.85c.32 0 .57.26.57.57z" />
      <path d="m61.91 56.12-.02-.36c-.06-1-.3-1.93-.72-2.76-.53-1.06-1.13-2.09-1.71-3.08l-.14-.24c-.43-.74-.87-1.47-1.31-2.21l-1.55-2.61c-.29-.5-.58-.99-.88-1.48l-4.46-7.46c-.18-.3-.51-.49-.86-.49-.35 0-.68.19-.86.49l-.57.96c-.45.77-.9 1.53-1.35 2.28-.56.94-1.12 1.87-1.68 2.8l-3.01 5.02c-.71 1.19-1.42 2.38-2.12 3.58l-.2.33c-.49.83-1 1.69-1.36 2.67-.59 1.61-.72 3.35-.4 5.31.26 1.58.95 3.1 2.05 4.5.74.93 1.59 1.73 2.53 2.35.77.51 1.56.93 2.42 1.27 1.14.45 2.28.71 3.38.77.25.01.49.02.73.02.54 0 1.07-.03 1.58-.05l.39-.02c.83-.04 1.7-.24 2.73-.62 1.47-.55 2.76-1.32 3.84-2.28 1.09-.97 1.95-2.11 2.57-3.38.73-1.5 1.06-3.07.99-4.68 0-.21-.02-.42-.04-.63zm-11.66 9.42c-3.43 0-6.23-2.79-6.23-6.23s2.79-6.23 6.23-6.23 6.23 2.79 6.23 6.23-2.79 6.23-6.23 6.23zm8.91-4.99c-.35.73-.8 1.38-1.33 1.98.42-.99.66-2.08.66-3.22 0-4.54-3.69-8.23-8.23-8.23s-8.23 3.69-8.23 8.23c0 1.12.23 2.19.63 3.16-.1-.12-.22-.22-.32-.35-.89-1.13-1.45-2.33-1.65-3.58-.26-1.62-.16-3.03.3-4.31.29-.81.73-1.55 1.2-2.33l.2-.34c.7-1.19 1.41-2.38 2.12-3.57l3.01-5.01c.56-.94 1.12-1.87 1.68-2.81.35-.6.71-1.19 1.06-1.8l3.59 6.01c.29.49.58.98.87 1.47l1.57 2.63c.44.72.87 1.45 1.3 2.18l.14.24c.56.97 1.15 1.98 1.65 2.98.29.59.47 1.25.51 1.97l.02.37c.01.2.03.39.03.59.06 1.27-.21 2.52-.79 3.71z" />
    </g>
  </BaseIcon>
))
FossileBrennstoffe.displayName = 'FossileBrennstoffe'
export default FossileBrennstoffe
