import { useFormStatus } from 'react-dom'
import { TextInput } from 'flowbite-react'
import { Fieldset, FieldsetLabel } from '../Fieldset'
import type {
  FormTextFieldFragment,
  FormEmailFieldFragment,
  FormNumberFieldFragment,
  FormPhoneFieldFragment,
} from '../'
import { FragmentOf } from '@/graphql'

export const Text = ({
  name,
  label,
  required,
  width,
  blockType,
  id,
  placeholder,
}:
  | FragmentOf<typeof FormTextFieldFragment>
  | FragmentOf<typeof FormEmailFieldFragment>
  | FragmentOf<typeof FormNumberFieldFragment>
  | FragmentOf<typeof FormPhoneFieldFragment>) => {
  const { pending } = useFormStatus()

  let inputMode: 'text' | 'email' | 'numeric' | 'tel' = 'text'
  let type = 'text'
  let pattern = undefined

  switch (blockType) {
    case 'email':
      type = 'email'
      break
    case 'number':
      inputMode = 'numeric'
      pattern = '[0-9]*'
      break

    case 'phone':
      type = 'tel'
      // prettier-ignore
      pattern = '^\+?[0-9 ]{0,20}' // eslint-disable-line no-useless-escape
      break

    default:
      break
  }
  return (
    <Fieldset width={width}>
      <FieldsetLabel {...{ id, required }}>{label}</FieldsetLabel>
      <TextInput
        id={id!}
        required={!!required}
        disabled={pending}
        placeholder={placeholder ?? ''}
        {...{ name, type, inputMode, pattern }}
      />
    </Fieldset>
  )
}
