import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Einkauf = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m33.92 75.43c-.62 0-1.22-.12-1.78-.37-.55-.24-1.04-.59-1.47-1.06s-.74-.99-.94-1.56c-.19-.57-.26-1.17-.21-1.8l3.56-41.71c.04-.51.46-.91.96-.94h4.74s.09-.42.09-.42c.48-2.32 1.57-4.44 3.16-6.14 2.24-2.39 5.21-3.71 8.37-3.71s6.13 1.32 8.37 3.71c1.59 1.7 2.68 3.82 3.16 6.14l.09.42h4.68c.54 0 .99.43 1.02.97l3.55 41.67c.05.63-.02 1.23-.21 1.8-.2.57-.51 1.09-.94 1.56s-.92.82-1.47 1.06l-.09.04c-.53.22-1.1.33-1.69.33h-32.96zm-2.36-4.62c-.03.35 0 .68.11.98s.27.58.51.83c.23.25.5.45.79.58.27.12.6.19.96.19h32.96c.33 0 .63-.05.89-.16.37-.16.63-.36.87-.61.23-.25.4-.53.5-.83s.14-.63.11-.98l-3.48-40.78h-3.55v.52c-.03.99-.16 1.98-.4 2.94-.26 1.05-.65 2.06-1.15 3-.18.33-.52.53-.9.53-.17 0-.33-.04-.48-.12-.49-.27-.68-.88-.41-1.37.43-.8.76-1.65.97-2.52.2-.79.3-1.61.32-2.43v-.54s-19.55 0-19.55 0v.54c.03.83.14 1.65.33 2.43.22.88.55 1.73.97 2.52l.05.09c.2.48 0 1.04-.46 1.29-.15.08-.31.12-.48.12-.38 0-.72-.2-.9-.53-.51-.94-.9-1.95-1.15-3-.24-.96-.37-1.95-.39-2.94v-.52h-3.56l-3.48 40.78zm18.85-51.04c-2.6 0-5.05 1.08-6.89 3.05-1.17 1.25-2.04 2.82-2.49 4.52l-.18.67h19.13l-.18-.67c-.46-1.7-1.32-3.27-2.49-4.52-1.84-1.97-4.29-3.05-6.89-3.05z" />
    </BaseIcon>
  ),
)
Einkauf.displayName = 'Einkauf'
export default Einkauf
