import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Geldscheine = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m85.4 22.32h-50.59c-2.76 0-5 2.24-5 5v3.29h-4.12c-2.76 0-5 2.24-5 5v3.78h-4.37c-2.76 0-5 2.24-5 5v23.15c0 2.76 2.24 5 5 5h50.59c2.75 0 5-2.24 5-5v-3.78h4.37c2.75 0 5-2.24 5-5v-3.29h4.12c2.76 0 5-2.24 5-5v-23.15c0-2.76-2.24-5-5-5zm-15.48 45.21c0 1.65-1.34 3-3 3h-50.59c-1.65 0-3-1.34-3-3v-23.15c0-1.65 1.34-3 3-3h50.59c1.65 0 3 1.34 3 3zm9.36-8.77c0 1.65-1.34 3-3 3h-4.37v-17.37c0-2.76-2.24-5-5-5h-44.21v-3.78c0-1.65 1.34-3 3-3h50.59c1.65 0 3 1.34 3 3v23.15zm9.12-8.29c0 1.65-1.34 3-3 3h-4.12v-17.85c0-2.76-2.24-5-5-5h-44.47v-3.29c0-1.65 1.34-3 3-3h50.59c1.65 0 3 1.34 3 3v23.15z" />
      <path d="m48.54 61.55c-.19-.26-.49-.41-.81-.41-.22 0-.42.07-.6.2-1.01.75-2.2 1.14-3.46 1.14-2.12 0-3.97-1.14-4.98-2.84h4.05c.56 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.79c-.05-.31-.08-.63-.08-.95 0-.15.01-.3.02-.45h4.94c.55 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.41c.93-1.97 2.93-3.34 5.25-3.34 1.23 0 2.41.38 3.4 1.1.17.12.37.19.59.19.32 0 .63-.16.82-.42.32-.45.22-1.08-.23-1.41-1.34-.97-2.92-1.48-4.58-1.48-3.45 0-6.38 2.25-7.41 5.35h-1.44c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.06c0 .15-.02.3-.02.45 0 .32.03.64.06.95h-1.01c-.55 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.53c1.17 2.84 3.97 4.85 7.23 4.85 1.69 0 3.3-.53 4.66-1.54.22-.16.36-.4.4-.66.04-.27-.03-.53-.19-.75z" />
    </g>
  </BaseIcon>
))
Geldscheine.displayName = 'Geldscheine'
export default Geldscheine
