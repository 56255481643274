import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Tipp = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m49.23 24.61c-.59 0-.96-.36-.97-.92-.05-2.65-.03-5.33 0-7.84 0-.27.07-.59.76-.95l.52.02c.44.23.75.59.72 1.62-.04 1.4-.03 2.8-.02 4.21v2.7c0 .71-.33 1.14-.91 1.17h-.09z" />
        <path d="m43.71 70.62c-.58 0-1-.12-1.19-.32-.15-.17-.21-.49-.17-.92.38-4.21-1.67-7.23-3.5-9.39l-.16-.19c-1.3-1.54-2.64-3.12-3.68-4.79-3.27-5.25-2.9-12.73.89-17.78 2.6-3.48 5.94-5.65 9.91-6.45 1.25-.25 2.48-.38 3.65-.38 3.88 0 7.44 1.38 10.58 4.1 3.25 2.8 5.11 6.4 5.54 10.68.45 4.48-.8 8.57-3.7 12.15l-.21.26c-1.31 1.62-2.66 3.29-3.91 4.97-1.25 1.69-1.83 3.58-1.74 5.62.01.31 0 .62 0 .94v.31c-.02.86-.34 1.18-1.19 1.19-.74 0-1.48.01-2.22.01h-8.92zm5.43-38.26c-2.03 0-3.98.43-5.8 1.28-4.25 1.98-7.25 5.65-8.23 10.08-.98 4.42.2 9.02 3.22 12.6 1.27 1.5 2.4 2.86 3.43 4.25 1.69 2.27 2.4 4.21 2.51 6.43v1.62s1.24 0 1.24 0h8.57s0-1.24 0-1.24c.01-2.29.84-4.4 2.68-6.84 1.07-1.42 2.25-2.85 3.3-4.11l.09-.11c3.27-3.95 4.28-8.44 3.01-13.35-1.6-6.14-7.5-10.61-14.02-10.61z" />
        <path d="m19.91 47.83c-.46 0-.92 0-1.38 0-.74 0-1.19-.37-1.2-.97 0-.29.08-.52.25-.7.25-.25.6-.31.86-.31 1.26-.02 2.52-.03 3.78-.03s2.48 0 3.73.03c.25 0 .59.06.82.3.17.18.25.43.24.73-.02.58-.45.94-1.17.95-.45 0-1.36 0-1.36 0h-4.58z" />
        <path d="m74.03 47.83c-.46 0-.92 0-1.38 0-.75 0-1.19-.36-1.2-.96 0-.3.08-.53.24-.71.25-.26.64-.32.91-.32 1.24-.01 2.48-.02 3.72-.02s2.44 0 3.66.02c.72 0 1.15.38 1.14 1 0 .62-.45.98-1.22.99-.47 0-.94 0-1.4 0h-4.48z" />
        <path d="m65.61 31.42c-.25 0-.49-.11-.68-.3-.39-.4-.37-.99.06-1.43 1.79-1.82 3.59-3.62 5.41-5.41.21-.21.42-.31.64-.31.13 0 .28.04.45.1.23.1.51.23.58 1.02-.11.21-.23.48-.36.61-1.83 1.91-3.72 3.77-5.34 5.36-.23.22-.5.34-.77.34z" />
        <path d="m32.78 31.43c-.2 0-.38-.08-.57-.26-.6-.55-1.17-1.13-1.75-1.71l-1.56-1.56c-.68-.68-1.37-1.36-2.04-2.05-.2-.21-.82-.93-.19-1.57.19-.19.4-.29.63-.29.31 0 .65.17.96.48.47.47 5.1 5.09 5.1 5.09.34.34.46.59.46 1-.02.34-.21.59-.57.76-.17.08-.33.12-.47.12z" />
        <path d="m55.27 75.82h-12.08c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h12.08c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
        <path d="m55.27 80.74h-12.08c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h12.08c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
      </g>
    </BaseIcon>
  ),
)
Tipp.displayName = 'Tipp'
export default Tipp
