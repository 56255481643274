import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Bankkarten = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m74.83 47.73h-7.88l6.23-6.23c2.42-2.42 2.42-6.35 0-8.76l-16.46-16.46c-2.42-2.42-6.35-2.42-8.76 0l-29.18 29.18c-2.42 2.42-2.42 6.35 0 8.76l8.59 8.59v14.4c0 3.42 2.78 6.2 6.2 6.2h41.26c3.42 0 6.2-2.78 6.2-6.2v-23.28c0-3.42-2.78-6.2-6.2-6.2zm-45.68 10.43h50.11v3.7h-50.11zm50.11-4.23v1.58h-50.11v-1.58c0-2.44 1.99-4.43 4.43-4.43h41.26c2.44 0 4.43 1.99 4.43 4.43zm-59.23-.97c-1.73-1.73-1.73-4.53 0-6.26l29.18-29.18c1.73-1.73 4.53-1.73 6.26 0l16.46 16.46c1.73 1.73 1.73 4.53 0 6.26l-7.48 7.48h-30.88c-3.42 0-6.2 2.78-6.2 6.2v6.38zm54.8 28.68h-41.26c-2.44 0-4.43-1.99-4.43-4.43v-12.69h50.11v12.69c0 2.44-1.99 4.43-4.43 4.43z" />
      <path d="m72.73 71.87h-3.66c-1.46 0-2.65 1.19-2.65 2.65v1.09c0 1.46 1.19 2.65 2.65 2.65h3.66c1.46 0 2.65-1.19 2.65-2.65v-1.09c0-1.46-1.19-2.65-2.65-2.65zm.88 3.74c0 .49-.4.88-.88.88h-3.66c-.49 0-.88-.4-.88-.88v-1.09c0-.49.4-.88.88-.88h3.66c.49 0 .88.4.88.88z" />
      <path d="m40.65 34.58c-.35-.35-.91-.35-1.25 0l-15.2 15.21c-.35.35-.35.91 0 1.25.17.17.4.26.63.26s.45-.09.63-.26l15.2-15.21c.35-.35.35-.91 0-1.25z" />
      <path d="m46.01 34.46c.5.5 1.17.78 1.88.78s1.38-.28 1.88-.78l6.22-6.22c.5-.5.78-1.17.78-1.88s-.28-1.38-.78-1.88l-.77-.77c-1-1-2.75-1-3.75 0l-6.22 6.22c-1.03 1.04-1.03 2.72 0 3.75l.77.77zm.48-3.27 6.22-6.22c.17-.17.39-.26.63-.26s.46.09.63.26l.77.77c.17.17.26.39.26.63s-.09.46-.26.63l-6.22 6.22c-.33.33-.92.34-1.25 0l-.77-.77c-.34-.34-.34-.91 0-1.25z" />
    </g>
  </BaseIcon>
))
Bankkarten.displayName = 'Bankkarten'
export default Bankkarten
