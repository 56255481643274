import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Suche = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m60.05 16.78c-12.2 0-22.12 9.92-22.12 22.12 0 5.03 1.69 9.68 4.54 13.4-.46.15-.9.38-1.25.74l-18.87 18.87c-.6.6-.93 1.4-.93 2.25s.33 1.65.93 2.25l.74.74c.6.6 1.4.93 2.25.93s1.65-.33 2.25-.93l18.87-18.87c.38-.38.64-.85.79-1.35 3.62 2.58 8.04 4.1 12.81 4.1 12.2 0 22.12-9.92 22.12-22.12s-9.92-22.12-22.12-22.12zm-15.01 40.08s-18.87 18.87-18.87 18.87c-.45.45-1.22.45-1.67 0l-.74-.74c-.22-.22-.35-.52-.35-.84s.12-.61.35-.84l18.87-18.87c.22-.22.52-.35.83-.35s.61.12.84.35l.74.74c.22.22.35.52.35.84s-.12.61-.35.83zm15.01 2.17c-11.1 0-20.12-9.03-20.12-20.12s9.03-20.12 20.12-20.12 20.12 9.03 20.12 20.12-9.03 20.12-20.12 20.12z" />
    </BaseIcon>
  ),
)
Suche.displayName = 'Suche'
export default Suche
