import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Immobilien = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
    <g>
      <path d="m42.23 81.78-15.74-.18c-1.87-.02-3.39-1.56-3.39-3.43v-36.23c0-.9.35-1.76.98-2.4l21.84-22.23c.65-.66 1.56-1.04 2.45-1.02.93 0 1.8.36 2.44 1.03l5.22 5.32c.35.36.35.94-.01 1.3s-.94.35-1.3-.01l-5.22-5.32c-.3-.31-.71-.48-1.14-.48-.43 0-.83.17-1.13.48l-21.84 22.23c-.29.3-.46.7-.46 1.12v36.23c0 .87.71 1.58 1.57 1.59l15.72.18 27.99-.02c.88 0 1.59-.71 1.59-1.59v-25.1c0-.51.41-.92.92-.92s.92.41.92.92v25.1c0 1.89-1.54 3.43-3.43 3.43l-27.99.02z" />
      <path d="m68.97 50.24c-8.09 0-14.67-6.58-14.67-14.67s6.58-14.67 14.67-14.67 14.67 6.58 14.67 14.67-6.58 14.67-14.67 14.67zm0-27.27c-6.95 0-12.61 5.66-12.61 12.61s5.66 12.61 12.61 12.61 12.61-5.66 12.61-12.61-5.66-12.61-12.61-12.61z" />
      <path d="m74.2 40.08c-.19-.26-.49-.41-.81-.41-.22 0-.42.07-.6.2-1.01.75-2.2 1.14-3.46 1.14-2.12 0-3.97-1.14-4.98-2.84h4.05c.56 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.79c-.05-.31-.08-.63-.08-.95 0-.15.01-.3.02-.45h4.94c.55 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.41c.93-1.97 2.93-3.34 5.25-3.34 1.23 0 2.41.38 3.4 1.1.17.12.37.19.59.19.32 0 .63-.16.82-.42.32-.45.22-1.08-.23-1.41-1.34-.97-2.92-1.48-4.58-1.48-3.45 0-6.38 2.25-7.41 5.35h-1.44c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.06c0 .15-.02.3-.02.45 0 .32.03.64.06.95h-1.01c-.55 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.53c1.17 2.84 3.97 4.85 7.23 4.85 1.69 0 3.3-.53 4.66-1.54.22-.16.36-.4.4-.66.04-.27-.03-.53-.19-.75z" />
    </g>
  </BaseIcon>
))
Immobilien.displayName = 'Immobilien'
export default Immobilien
