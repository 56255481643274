// import Link from 'next/link'
import type { Button, ButtonSizes } from 'flowbite-react'

interface CMSLinkType {
  link: CMSLink | null
  children?: React.ReactNode
  className?: string
  as?: typeof Button
  size?: keyof ButtonSizes
  color?: 'info' | 'light'
}

export const CMSLink: React.FC<CMSLinkType> = ({
  link,
  children,
  className,
  as: Component = 'a',
  size,
  color = 'info',
}) => {
  if (!link) return null
  const href = CMSHref(link)
  if (href === '') return null

  const { newTab, label, appearance } = link

  const target = newTab ? '_blank' : undefined
  const rel = href?.startsWith('http') ? 'noopener noreferrer' : undefined
  const prefetch = href?.startsWith('http') ? false : undefined
  const outline = appearance === 'outline' ? true : undefined
  const as = Component !== 'a' ? 'a' : undefined

  return (
    <Component
      {...{ as, href, target, rel, className, outline, prefetch, color, size }}
    >
      {label}
      {children}
    </Component>
  )
}

export interface CMSLink {
  type?: 'custom' | 'reference' | null
  url?: string | null
  newTab?: boolean | null
  reference?: {
    value: {
      path: string | null
    } | null
    relationTo?: 'pages' | null
  } | null
  label?: string | null
  appearance?: 'default' | 'outline' | null
}

export const CMSHref = (props: CMSLink | null) => {
  if (!props) return ''
  const { type, url, reference } = props
  let href = ''

  // custom links use the url directly
  if (type === 'custom') {
    const nonRedirectElements = ['#', '/', 'https:', 'http:']
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (nonRedirectElements.some((element) => url?.startsWith(element))) {
      return url ?? ''
    }
    return url ? `/${url}` : ''
  }

  // internal references use path for nested pages
  if (
    type === 'reference' &&
    reference?.value &&
    typeof reference.value === 'object' &&
    'path' in reference.value
  ) {
    href = reference.value.path ?? ''
  }
  return href
}
