import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Inflation = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m79.58 30.11h-50.59c-2.76 0-5 2.24-5 5v18.35l-8.71-9.29c-.38-.4-1.01-.42-1.41-.05-.4.38-.42 1.01-.05 1.41l13.32 14.21c.19.2.45.34.74.32.28 0 .55-.12.73-.33l7.39-8.18 16.02 17.46-7.12.11c-.55 0-.99.46-.98 1.02 0 .55.45.98 1 .98h.02l9.13-.14c.07.01.13.04.2.04h.02c.48 0 .85-.36.95-.81.01-.06.03-.11.03-.17 0-.01 0-.03 0-.04l-.15-9.73c0-.55-.45-.98-1-.98h-.02c-.55 0-.99.46-.98 1.02l.11 7.06-16.5-17.97c-.19-.21-.5-.31-.74-.32-.28 0-.55.12-.74.33l-7.4 8.19-1.87-2v-20.49c0-1.65 1.34-3 3-3h50.59c1.65 0 3 1.34 3 3v23.15c0 1.65-1.34 3-3 3h-20.68c-.55 0-1 .45-1 1s.45 1 1 1h20.68c2.76 0 5-2.24 5-5v-23.15c0-2.76-2.24-5-5-5z" />
        <path d="m47.64 48.89c0 .56.45 1.01 1.01 1.01h1.53c1.17 2.84 3.97 4.85 7.23 4.85 1.69 0 3.3-.53 4.66-1.54.22-.16.36-.4.4-.66.04-.27-.03-.53-.19-.75-.19-.26-.49-.41-.81-.41-.22 0-.42.07-.6.2-1.01.75-2.2 1.14-3.46 1.14-2.12 0-3.97-1.14-4.98-2.84h4.05c.56 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.79c-.05-.31-.08-.63-.08-.95 0-.15.01-.3.02-.45h4.94c.55 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.41c.93-1.97 2.93-3.34 5.25-3.34 1.23 0 2.41.38 3.4 1.1.17.12.37.19.59.19.32 0 .63-.16.82-.42.32-.45.22-1.08-.23-1.41-1.34-.97-2.92-1.48-4.58-1.48-3.45 0-6.38 2.25-7.41 5.35h-1.44c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.06c0 .15-.02.3-.02.45 0 .32.03.64.06.95h-1.01c-.55 0-1.01.45-1.01 1.01z" />
      </g>
    </BaseIcon>
  ),
)
Inflation.displayName = 'Inflation'
export default Inflation
