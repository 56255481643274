import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Sparen = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m87.17 56.72c-.13-.42-.53-.71-.97-.71-.1 0-.21.02-.3.05-1.51.48-2.87 1.04-4.04 1.69-1.01-.76-2.13-1.15-3.32-1.15-1.1 0-2.06.34-2.7.64-1.88-10.8-13.49-18.92-27.08-18.92-3.73 0-7.36.6-10.7 1.78-.29-.15-.59-.32-.98-.54-1.69-.94-3.79-2.11-6.47-2.11-1.47 0-2.99.36-4.52 1.08-.3.14-.51.41-.57.73s.04.65.26.89l4.07 4.28c-3.57 2.74-6.14 6.21-7.44 10.02h-.99c-1.51 0-2.73 1.23-2.73 2.73v6.25c0 1.51 1.22 2.73 2.73 2.73h1.05l.04.11c1.63 4.06 4.23 7.54 7.53 10.06l-1.75 5.26c-.24.72-.12 1.52.33 2.14.44.62 1.16.98 1.92.98h5.64c1.02 0 1.93-.65 2.25-1.63l.74-2.23 3.13.82c2.09.41 4.25.61 6.43.61 3.09 0 6.11-.41 8.99-1.21l.66 2c.32.97 1.23 1.63 2.25 1.63h5.64c.76 0 1.48-.37 1.92-.98.45-.62.57-1.42.33-2.14l-1.61-4.85c4.89-3.49 8.05-8.25 8.91-13.38.01-.06.34-1.44.23-3.91.45-.31 1.36-.82 2.43-.82.51 0 1.01.12 1.49.35-1.81 1.42-2.77 3.06-2.62 4.51.14 1.41 1.23 2.36 2.92 2.55.15.02.29.02.43.02 1.3 0 2.33-.67 2.9-1.88.68-1.45.54-3.4-.34-4.89.95-.49 2.04-.93 3.23-1.3.53-.17.83-.74.67-1.27zm-13.33 6.23c-.82 4.87-3.91 9.33-8.7 12.55-.38.25-.54.73-.4 1.16l1.85 5.57c.05.15 0 .25-.05.31-.04.05-.12.14-.28.14h-5.64c-.15 0-.28-.09-.32-.23l-.97-2.94c-.14-.42-.53-.7-.96-.7-.1 0-.21.02-.3.05-2.98.93-6.11 1.4-9.31 1.4-2.05 0-4.07-.19-6-.56l-3.99-1.05c-.08-.02-.17-.03-.26-.03-.44 0-.83.28-.97.7l-1.04 3.14c-.05.14-.18.23-.32.23h-5.64c-.14 0-.23-.08-.27-.14-.04-.05-.09-.16-.05-.31l1.98-5.96c.14-.43 0-.89-.38-1.15-3.27-2.32-5.83-5.64-7.43-9.6l-.3-.75c-.16-.39-.53-.64-.94-.64h-1.73c-.38 0-.7-.31-.7-.7v-6.25c0-.38.31-.7.7-.7h1.73c.45 0 .85-.29.98-.73 1.19-4.02 3.9-7.6 7.84-10.36.24-.17.4-.44.43-.73.03-.3-.07-.59-.28-.8l-3.82-4.02c.8-.25 1.57-.37 2.33-.37 2.11 0 3.81.95 5.46 1.87.49.27.96.53 1.41.76.14.07.3.1.45.1.12 0 .23-.02.34-.06 3.31-1.19 6.83-1.79 10.47-1.79 13.31 0 24.41 8.19 25.26 18.66 0 .1.03.2.06.25.06 2.09-.16 3.34-.23 3.69zm7.93.37c-.22.48-.58.71-1.08.71-.06 0-.12 0-.19-.01-.49-.05-1.08-.23-1.13-.73-.06-.59.48-1.71 2.16-2.94.51.9.62 2.15.23 2.97z" />
        <path d="m53.37 44.88h-8.01c-.56 0-1.02.46-1.02 1.02s.46 1.02 1.02 1.02h8.01c.56 0 1.02-.46 1.02-1.02s-.46-1.02-1.02-1.02z" />
        <path d="m49.37 35.73c-6.88 0-12.47-5.6-12.47-12.47s5.6-12.47 12.47-12.47 12.47 5.6 12.47 12.47-5.6 12.47-12.47 12.47zm0-22.98c-5.8 0-10.51 4.72-10.51 10.51s4.72 10.51 10.51 10.51 10.51-4.72 10.51-10.51-4.72-10.51-10.51-10.51z" />
        <path d="m49.68 29.65c-2.68 0-5.1-1.6-6.16-4.07l-1.22-.02c-.53 0-.96-.43-.96-.96s.43-.96.96-.96h.69c0-.25 0-.47 0-.69v-.17s-.02-.09-.02-.09h-.74c-.53 0-.96-.43-.96-.96s.43-.96.96-.96h1.11c.97-2.71 3.51-4.51 6.35-4.51 1.42 0 2.78.44 3.93 1.27.43.31.53.91.22 1.34-.18.25-.47.4-.78.4-.2 0-.4-.06-.56-.18-.82-.59-1.79-.9-2.8-.9-1.75 0-3.36.97-4.2 2.54l3.49.04c.53 0 .96.43.96.96s-.43.96-.96.96h-4.02l-.04.13s-.01.09-.01.13c0 .2.03.38.05.57v.03s.04.09.04.09h3.9c.53 0 .96.43.96.96s-.43.96-.96.96h-3.16c.87 1.37 2.35 2.16 3.94 2.16 1.03 0 2.02-.33 2.85-.94.17-.12.36-.19.57-.19.3 0 .59.15.78.39.15.21.22.46.18.71s-.17.48-.38.63c-1.16.86-2.54 1.32-4 1.32z" />
      </g>
    </BaseIcon>
  ),
)
Sparen.displayName = 'Sparen'
export default Sparen
