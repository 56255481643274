import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Laptop = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m80.34 68.2h-60.68c-2.76 0-5-2.24-5-5v-35.09c0-2.75 2.24-5 5-5h60.68c2.76 0 5 2.24 5 5v35.1c0 2.76-2.24 5-5 5zm-60.68-43.09c-1.65 0-3 1.34-3 3v35.1c0 1.65 1.34 3 3 3h60.68c1.65 0 3-1.34 3-3v-35.1c0-1.65-1.34-3-3-3z" />
        <path d="m53.99 29.74h-7.98c-.55 0-1-.45-1-1s.45-1 1-1h7.98c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m84.34 72.74h-68.68c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h68.67c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
      </g>
    </BaseIcon>
  ),
)
Laptop.displayName = 'Laptop'
export default Laptop
