import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Nachricht = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <path d="m80.65 56.56-.31-.58.35-.12c.25-.09.5-.17.75-.25.52-.16 1.02-.32 1.46-.55 1.95-1.04 2.95-2.66 2.96-4.82.04-7.94.02-16.12 0-23.34 0-3.02-2.46-5.49-5.47-5.49-5.76 0-11.52 0-17.28 0s-11.52 0-17.28 0c-3.01 0-5.46 2.45-5.47 5.45v7.56 3.42h-12.14s-9.01 0-9.01 0c-2.62 0-4.75 2.14-4.76 4.77-.01 6.58-.02 13.15 0 19.73 0 1.85.9 3.3 2.57 4.19.38.21.81.34 1.25.48.21.07.42.13.64.21l.12.12-.16.28c-1.07 1.91-2.41 3.67-3.96 5.23-.5.5-.59.89-.34 1.36.19.35.46.51.86.51.15 0 .32-.02.5-.07 2.47-.63 4.84-1.53 7.02-2.68 2.47-1.29 4.4-2.74 5.88-4.44.27-.31.54-.43.94-.43h11.39 7.16c2.66 0 4.84-2.14 4.84-4.77 0-1.24 0-6.17 0-6.6h6.82 7.9c.51 0 .87.17 1.22.57 1.74 1.99 4.01 3.69 6.92 5.21 2.58 1.35 5.36 2.41 8.28 3.16.21.05.39.08.56.08.41 0 .68-.16.88-.52.22-.41.25-.81-.37-1.43-1.85-1.86-3.44-3.96-4.71-6.23zm-29.49 5.54c0 1.82-1.18 2.99-3.04 2.99 0 0-15.19 0-19.17 0-.51 0-.81.12-1.04.41-1.49 1.92-3.47 3.46-6.25 4.85-.56.28-1.14.54-1.72.8l-.79.36-.16-.02-.11-.19.33-.47c.21-.29.42-.59.59-.9.62-1.11 1.23-2.2 1.78-3.31.19-.38.2-.75.02-1.04-.18-.3-.56-.48-1.02-.49h-.63c-.39 0-.8 0-1.21-.07-1.27-.19-2.24-1.28-2.31-2.61 0-.1 0-.21 0-.31v-19.27c0-1.82 1.18-3 3-3h20.91v4.4 6.01c0 3.02 2.51 5.48 5.58 5.49h5.23s0 6.37 0 6.37zm29.48-.74-1.2-.54c-.69-.31-1.37-.62-2.04-.95-3.31-1.66-5.68-3.5-7.45-5.79-.24-.3-.55-.42-1.11-.42h-22.71c-2.22 0-3.71-1.48-3.71-3.69v-22.8c0-2.25 1.45-3.7 3.7-3.7h33.98c2.25 0 3.7 1.45 3.7 3.7v22.86.32c-.08 1.64-1.28 3-2.86 3.23-.49.07-.99.08-1.46.08h-.73c-.49.01-.88.19-1.08.51-.18.29-.17.68.03 1.08.65 1.32 1.37 2.6 2.1 3.91.2.36.44.7.68 1.04.11.16.23.33.34.49l.06.25-.25.42z" />
    </BaseIcon>
  ),
)
Nachricht.displayName = 'Nachricht'
export default Nachricht
