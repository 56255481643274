import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const InfoCircle = forwardRef<
  SVGSVGElement,
  PropsWithoutRef<FlowbiteIconProps>
>((props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
  <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 48 48" {...props}>
    <path d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103988 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C48 17.6348 45.4714 11.5303 40.9706 7.02944C36.4697 2.52856 30.3652 0 24 0ZM24 44.3077C19.9835 44.3077 16.0572 43.1167 12.7177 40.8852C9.37808 38.6538 6.77519 35.4822 5.23815 31.7714C3.70111 28.0607 3.29895 23.9775 4.08252 20.0382C4.8661 16.0989 6.80022 12.4804 9.6403 9.64029C12.4804 6.80021 16.0989 4.86609 20.0382 4.08251C23.9775 3.29894 28.0607 3.7011 31.7714 5.23814C35.4822 6.77518 38.6538 9.37807 40.8852 12.7176C43.1167 16.0572 44.3077 19.9835 44.3077 24C44.3013 29.384 42.1597 34.5456 38.3527 38.3527C34.5456 42.1597 29.384 44.3013 24 44.3077ZM26.1545 34.9551H28.9846V37.3846H19.0154V34.9551H21.84V22.8517H19.0154V20.424H26.1489L26.1545 34.9551ZM21.2972 13.3274C21.2972 12.7928 21.4558 12.2703 21.7527 11.8258C22.0497 11.3813 22.4718 11.0349 22.9657 10.8304C23.4596 10.6258 24.003 10.5723 24.5273 10.6765C25.0516 10.7808 25.5332 11.0382 25.9112 11.4162C26.2891 11.7942 26.5466 12.2758 26.6508 12.8001C26.7551 13.3244 26.7016 13.8678 26.497 14.3617C26.2925 14.8556 25.9461 15.2777 25.5016 15.5747C25.0571 15.8716 24.5346 16.0302 24 16.0302C23.2832 16.0302 22.5957 15.7454 22.0889 15.2385C21.582 14.7317 21.2972 14.0442 21.2972 13.3274Z" />
  </BaseIcon>
))
InfoCircle.displayName = 'InfoCircle'
export default InfoCircle
