import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Finanzen = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m20.23 74.74c-2.34 0-4.38-1.75-4.74-4.06l-1.98-12.8c-.4-2.61 1.39-5.07 4.01-5.47l1.74-.27c.25-.04.49-.06.74-.06 1.64 0 3.18.86 4.05 2.25 3.2-3.96 8.74-6.8 13.83-7.1.32-.02.64-.03.96-.03 3.68 0 6.73 1.22 8.83 3.53l7.37.48c1.93.07 3.32.65 4.15 1.74.63.83.76 1.76.74 2.43l19.96-8.14c.32-.17 1.52-.72 3.03-.72 1.18 0 2.81.35 3.93 2.03.62.92.81 1.91.59 2.93-.5 2.28-3.01 3.84-3.12 3.91l-20.97 13.94c-1.99 1.32-4.41 2.05-6.81 2.05-.22 0-.44 0-.65-.02l-29.14-1.34c-.16 2.21-1.85 4.05-4.05 4.39l-1.74.27c-.25.04-.49.06-.74.06zm-.24-20.72c-.14 0-.29.01-.44.03l-1.74.27c-.75.12-1.41.52-1.86 1.13s-.63 1.37-.52 2.12l1.98 12.8c.21 1.38 1.42 2.42 2.81 2.42.15 0 .29-.01.44-.03l1.74-.27c.75-.12 1.41-.52 1.86-1.13s.63-1.37.52-2.12l-1.98-12.8c-.21-1.38-1.42-2.41-2.82-2.41zm36.01 15.4c.19.01.38.02.57.02 2 0 4.03-.61 5.71-1.73l21-13.95c.54-.34 2-1.48 2.27-2.69.11-.49 0-.96-.31-1.43-.52-.78-1.29-1.17-2.3-1.17-1.16 0-2.2.52-2.24.54l-21.42 8.74-.09-.08v.1c-1.21 2.3-3.47 2.93-4.61 2.93l-10.04-.65c-.54-.03-.94-.5-.91-1.03.03-.51.46-.91.98-.91l10.01.65c.26 0 2.6-.17 3.34-3.11.02-.09.15-.9-.33-1.53-.45-.59-1.38-.92-2.69-.97l-7.83-.53c-.26-.02-.51-.14-.68-.34-1.7-2.02-4.39-3.13-7.58-3.13-.28 0-.57 0-.85.03-5.01.29-10.54 3.4-13.16 7.37l1.75 11.53 29.42 1.36z" />
        <path d="m59.9 48.73c-8.06 0-14.62-6.56-14.62-14.62s6.56-14.62 14.62-14.62 14.62 6.56 14.62 14.62-6.56 14.62-14.62 14.62zm0-27.27c-6.98 0-12.66 5.68-12.66 12.66s5.68 12.66 12.66 12.66 12.66-5.68 12.66-12.66-5.68-12.66-12.66-12.66z" />
        <path d="m65.13 38.62c-.19-.26-.49-.41-.81-.41-.22 0-.42.07-.6.2-1.01.75-2.2 1.14-3.46 1.14-2.12 0-3.97-1.14-4.98-2.84h4.05c.56 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.79c-.05-.31-.08-.63-.08-.95 0-.15.01-.3.02-.45h4.94c.55 0 1.01-.45 1.01-1.01s-.45-1.01-1.01-1.01h-4.41c.93-1.97 2.93-3.34 5.25-3.34 1.23 0 2.41.38 3.4 1.1.17.12.37.19.59.19.32 0 .63-.16.82-.42.32-.45.22-1.08-.23-1.41-1.34-.97-2.92-1.48-4.58-1.48-3.45 0-6.38 2.25-7.41 5.35h-1.44c-.56 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.06c0 .15-.02.3-.02.45 0 .32.03.64.06.95h-1.01c-.55 0-1.01.45-1.01 1.01s.45 1.01 1.01 1.01h1.53c1.17 2.84 3.97 4.85 7.23 4.85 1.69 0 3.3-.53 4.66-1.54.22-.16.36-.4.4-.66.04-.27-.03-.53-.19-.75z" />
      </g>
    </BaseIcon>
  ),
)
Finanzen.displayName = 'Finanzen'
export default Finanzen
