import { forwardRef, type PropsWithoutRef } from 'react'
import { BaseIcon, type FlowbiteIconProps } from 'flowbite-react-icons'

const Diagramm1 = forwardRef<SVGSVGElement, PropsWithoutRef<FlowbiteIconProps>>(
  (props, ref /* eslint-disable react/jsx-props-no-spreading */) => (
    <BaseIcon fill="currentColor" ref={ref} viewBox="0 0 100 98" {...props}>
      <g>
        <path d="m82.49 68.62h-64.61c-.55 0-1-.45-1-1v-42.2c0-.55.45-1 1-1s1 .45 1 1v41.2h63.61c.55 0 1 .45 1 1s-.45 1-1 1z" />
        <path d="m37.88 61.24h-8.21c-.55 0-1-.45-1-1v-33.18c0-.55.45-1 1-1h8.21c.55 0 1 .45 1 1v33.18c0 .55-.45 1-1 1zm-7.21-2h6.21v-31.18h-6.21z" />
        <path d="m52.88 61.24h-8.21c-.55 0-1-.45-1-1v-23.52c0-.55.45-1 1-1h8.21c.55 0 1 .45 1 1v23.52c0 .55-.45 1-1 1zm-7.21-2h6.21v-21.52h-6.21z" />
        <path d="m67.72 61.24h-8.21c-.55 0-1-.45-1-1v-16.59c0-.55.45-1 1-1h8.21c.55 0 1 .45 1 1v16.59c0 .55-.45 1-1 1zm-7.21-2h6.21v-14.59h-6.21z" />
      </g>
    </BaseIcon>
  ),
)
Diagramm1.displayName = 'Diagramm1'
export default Diagramm1
