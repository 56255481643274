'use client'

import { useState } from 'react'
import { Alert } from 'flowbite-react'
import { InfoCircle, ExclamationCircle } from 'flowbite-react-icons/solid'
import { useTranslations } from 'next-intl'
import { type Props } from './'
import { CMSLink } from '@/components/CMSLink'

type ItemType = 'failure' | 'warning' | 'info'

const typeOrder: Record<ItemType, number> = {
  failure: 1,
  warning: 2,
  info: 3,
}

const getAnnouncementToDisplay = (items: Props['docs']) => {
  const currentTime = new Date().toISOString()

  const filteredItems = items
    // announcement has not expired
    ?.filter((item) => item?.endTime && item.endTime > currentTime)
    // announcement has started
    .filter((item) => item?.startTime && item.startTime <= currentTime)
    // announcement has not been dismissed
    ?.filter(
      (item) =>
        item?.id &&
        sessionStorage.getItem(`announcement${item.id}Dismissed`) === null,
    )
    // use most recently updated as tie breaker for same type
    .sort((a, b) =>
      a?.updatedAt && b?.updatedAt
        ? new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        : 0,
    )
    // primarly sort by type
    .sort((a, b) =>
      a?.type && b?.type ? typeOrder[a.type] - typeOrder[b.type] : 0,
    )
  return filteredItems ? filteredItems[0] : null
}

// having a default export makes dynamic imports easier
export default function Announcement({ docs }: Props) {
  const t = useTranslations('Announcements')
  const [announcement, setAnnouncement] = useState(
    getAnnouncementToDisplay(docs ?? []),
  )

  if (!announcement?.id) return null

  const dismissAnnouncement = () => {
    sessionStorage.setItem(`announcement${id}Dismissed`, 'true')
    setAnnouncement(getAnnouncementToDisplay(docs ?? []))
  }
  const { title, description, type, id, link } = announcement
  const translatedLink = {
    ...link,
    label: t('learnMore'),
  }
  return (
    <Alert
      color={type ?? 'info'}
      className="[&>div>div]:grow [&>div>div]:items-center [&>div>div]:lg:flex [&>div]:w-full"
      icon={type == 'info' ? InfoCircle : ExclamationCircle}
      rounded={false}
    >
      <div className="mb-4 grow text-gray lg:mb-0">
        <b>{title}</b>: <span>{description}</span>
      </div>
      {link && (
        <CMSLink
          link={translatedLink}
          className="whitespace-nowrap text-primary hover:underline lg:ml-4"
        />
      )}
      <button
        className="ml-6 cursor-pointer whitespace-nowrap text-primary hover:underline"
        onClick={dismissAnnouncement}
      >
        {t('dismiss')}
      </button>
    </Alert>
  )
}
