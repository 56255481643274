'use client'

import { useState } from 'react'
import { Play } from 'flowbite-react-icons/solid'
import Image from 'next/image'
import { cmsProxyUrl, cn } from '@/lib/utils'

export const VideoPlayer = (props: {
  posterAlt?: string | null
  posterUrl?: string | null
  children: React.ReactNode
}) => {
  const { posterUrl, posterAlt, children } = props
  const [isPlaying, setIsPlaying] = useState(false)
  return (
    <div
      className={cn(
        'relative aspect-video overflow-hidden rounded-lg bg-black',
      )}
    >
      {!isPlaying && posterUrl && (
        <Image
          src={cmsProxyUrl(posterUrl)}
          alt={posterAlt ?? 'Video poster'}
          width={1060}
          height={596}
          className="z-0 object-fill object-center opacity-50"
          sizes="(min-width: 1280px) 1060px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100w"
        />
      )}
      {!isPlaying && (
        <button
          className="absolute left-1/2 top-1/2 z-10 flex size-20 -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-white bg-opacity-50 transition-all duration-300 ease-out hover:scale-110 hover:bg-opacity-60 focus:scale-110 focus:bg-opacity-60 focus:outline-none"
          onClick={() => setIsPlaying(true)}
        >
          <Play className="ml-1 size-12 text-white" />
        </button>
      )}
      {isPlaying && children}
    </div>
  )
}
