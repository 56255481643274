'use client'

import { usePathname } from 'next/navigation'
import { useState, useMemo } from 'react'
import { Button, RangeSlider, Card, ButtonGroup, Modal } from 'flowbite-react'
import { Euro } from 'flowbite-react-icons/outline'
import { QuestionCircle } from 'flowbite-react-icons/solid'
// import Link from 'next/link'
import styles from './styles.module.css'
import { Props } from './'
import { FormattedNumericInput } from '@/components/FormattedNumericInput'
import { cn, formatNumber } from '@/lib/utils'
import { RichText } from '@/components/RichText'

const loanTypes = {
  consumption: 'Konsumkredit',
  home: 'Wohnbaufinanzierung',
} as const
type LoanType = keyof typeof loanTypes

interface CalculatorInputSettings {
  min: number
  max: number
  sliderMax?: number
  initial: number
  step: number
  marks: number[]
}

interface LoanSettings {
  amount: CalculatorInputSettings
  term: CalculatorInputSettings
}

const initialSettings: Record<LoanType, LoanSettings> = {
  consumption: {
    amount: {
      min: 1_000,
      max: 50_000,
      sliderMax: 50_000,
      initial: 10_000,
      step: 1_000,
      marks: [1_000, 10_000, 20_000, 30_000, 40_000, 50_000],
    },
    term: {
      min: 1,
      max: 10,
      step: 1,
      initial: 3,
      marks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
  },
  home: {
    amount: {
      min: 10_000,
      max: 5_000_000,
      sliderMax: 1_000_000,
      initial: 250_000,
      step: 10_000,
      marks: [10_000, 250_000, 500_000, 750_000, 1_000_000],
    },
    term: {
      min: 3,
      max: 35,
      step: 1,
      initial: 10,
      marks: [3, 5, 10, 15, 20, 25, 30, 35],
    },
  },
}

export const LoanCalculator = (props: Props) => {
  const { loanCalculator, appointmentsPage } = props ?? {}
  const { interestRates, content_html } = loanCalculator ?? {}
  const pathname = usePathname()
  const [loanType, setLoanType] = useState<LoanType>('consumption')
  const [interestRateType, setInterestRateType] = useState<
    'flexible' | 'fixed'
  >('flexible' as const)
  const interestRate =
    (interestRates?.[loanType]?.[interestRateType] ?? 0) / 1000
  const settings = initialSettings[loanType]
  const [loanAmount, setLoanAmount] = useState(settings.amount.initial)
  const [loanTerm, setLoanTerm] = useState(settings.term.initial)

  const selectLoanType = (selectedLoanType: LoanType) => {
    const settings = initialSettings[selectedLoanType]
    setLoanAmount(settings.amount.initial)
    setLoanTerm(settings.term.initial)
    setLoanType(selectedLoanType)
  }

  const monthlyPayment = useMemo(
    () => calculateMonthlyPayment(loanAmount, interestRate, loanTerm * 12),
    [loanAmount, loanTerm, interestRate],
  )
  const calculatorDetailsMessage = `Kreditrechner Details:
-------------------------------
Kreditart: ${loanTypes[loanType]}
Kreditbetrag in EUR: ${formatNumber(loanAmount)}
Laufzeit in Jahren: ${loanTerm}
Zinstyp: ${interestRateType === 'fixed' ? 'Fixer Zinssatz' : 'Variabler Zinssatz'}
Angenommener Zinssatz: ${formatNumber(interestRate, 3)}%
-------------------------------`

  const appointmentBody = `Sehr geehrte Damen und Herren,

bitte um Kontaktaufnahme für einen Beratungstermin.

${calculatorDetailsMessage}

Mit freundlichen Grüßen`

  return (
    <>
      <Card className="mx-auto max-w-[80rem] [&>div]:p-0">
        <div className="flex-row gap-4 lg:flex">
          <div className="grow space-y-6 p-6">
            <h2 className="text-2xl font-bold">Kreditrechner</h2>
            <div className="">
              <h3 className="mb-4 font-semibold">Kreditart</h3>
              <ButtonGroup>
                {Object.entries(loanTypes).map(([type, name]) => (
                  <Button
                    key={type}
                    color={loanType === type ? 'info' : 'light'}
                    onClick={() => selectLoanType(type as LoanType)}
                  >
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <div className="space-y-4">
              <h3 className="font-semibold">Kreditbetrag in Euro</h3>
              <div className="gap-8 xl:flex">
                <div className="mb-4 xl:mb-0 xl:grow">
                  <RangeSlider
                    min={settings.amount.min}
                    max={settings.amount.sliderMax}
                    step={settings.amount.step}
                    value={loanAmount}
                    className={styles['slider']}
                    onChange={(e) => setLoanAmount(parseInt(e.target.value))}
                  />
                  <SliderMarks
                    marks={settings.amount.marks}
                    adjustRight={true}
                  />
                </div>
                <FormattedNumericInput
                  name="amount"
                  className="max-w-40"
                  color="white"
                  icon={Euro}
                  sizing="lg"
                  value={loanAmount}
                  setValue={setLoanAmount}
                  min={settings.amount.min}
                  max={settings.amount.max}
                />
              </div>
            </div>
            <div className="space-y-4">
              <h3 className="font-semibold">Laufzeit in Jahren</h3>
              <div className="gap-8 xl:flex">
                <div className="mb-4 xl:grow">
                  <RangeSlider
                    min={settings.term.min}
                    max={settings.term.max}
                    step={settings.term.step}
                    value={loanTerm}
                    className={styles['slider']}
                    onChange={(e) => setLoanTerm(parseInt(e.target.value))}
                  />
                  <SliderMarks
                    marks={settings.term.marks}
                    adjustLeft={loanType == 'home'}
                  />
                </div>

                <FormattedNumericInput
                  name="term"
                  className="max-w-40"
                  color="white"
                  sizing="lg"
                  value={loanTerm}
                  setValue={setLoanTerm}
                  min={settings.term.min}
                  max={settings.term.max}
                />
              </div>
            </div>
            <div className="space-y-4">
              <h3 className="font-semibold">Zinstyp</h3>
              <ButtonGroup>
                <Button
                  color={interestRateType == 'fixed' ? 'info' : 'light'}
                  onClick={() => setInterestRateType('fixed')}
                >
                  Fixer Zinssatz
                </Button>
                <Button
                  color={interestRateType == 'flexible' ? 'info' : 'light'}
                  onClick={() => setInterestRateType('flexible')}
                >
                  Variabler Zinssatz
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="grow flex-col space-y-6 bg-gray-50 p-6 lg:max-w-sm lg:grow-0 xl:max-w-lg">
            <h3 className="text-2xl font-semibold">Rechenbeispiel</h3>
            <p className="flex items-center justify-between">
              <span>Monatliche Rate:</span>
              <strong className="text-3xl">
                € {formatNumber(monthlyPayment)}
              </strong>
            </p>
            <p className="flex justify-between">
              <span>Angenommener Zinssatz:</span>
              <span>{formatNumber(interestRate, 3)}% *</span>
            </p>
            <p className="flex justify-between">
              <span>Gesamter Rückzahlungsbetrag: </span>
              <span>€ {formatNumber(monthlyPayment * 12 * loanTerm, 0)}</span>
            </p>
            <Button
              className="w-full"
              type="submit"
              as={'a'}
              href={`${appointmentsPage?.path}?message=${encodeURIComponent(appointmentBody)}&originURL=${encodeURIComponent(pathname)}`}
            >
              Beratungstermin vereinbaren
            </Button>
            <div className="self grow flex-col justify-end self-end justify-self-end text-sm text-gray-600 lg:flex">
              <p className="mb-4 flex items-center">
                <span className="min-w-7">
                  <QuestionCircle className="size-5" />
                </span>
                <span>
                  Bitte beachten Sie, dass alle Angaben unverbindlich sind und
                  die genannten Konditionen sich ändern können.{` `}
                  {content_html && (
                    <LoanCalculatorModal
                      buttonText="Mehr erfahren"
                      headerText="Gesetzliche Informationen"
                    >
                      <RichText content={content_html} />
                    </LoanCalculatorModal>
                  )}
                </span>
              </p>
              <p className="flex items-center">
                <span className="min-w-7 pl-2">*</span>
                <span>
                  Der Zinssatz sowie die resultierende Rate hängen von Ihrer
                  Bonität ab und werden erst nach einer entsprechenden
                  Bonitätsprüfung festgelegt.
                </span>
              </p>
            </div>
          </div>
        </div>
      </Card>
      <div className="mt-6 text-center text-gray-600">
        Hierbei handelt es sich um eine MARKETINGMITTEILUNG der Medieninhaberin.
        Die Berechnung erfolgt auf Basis Ihrer Eingaben, sie dient als erste
        Orientierung und ist ohne Gewähr.
      </div>
    </>
  )
}

// Client provided monthly loan payback rate formula:
// ZF = (Z/100 + 1)^(1/12),
// R = B * [(ZF ^ m) / (ZF ^ m - 1)] * (ZF - 1)
// where:
// Z = annual interest rate
// ZF = interest factor
// R = monthly payment rate
// B = principal amount
// m = term in months
export function calculateMonthlyPayment(
  principalAmount: number,
  annualInterestRate: number,
  termInMonths: number,
) {
  if (annualInterestRate === 0) return principalAmount / termInMonths
  const interestFactor = Math.pow(annualInterestRate / 100 + 1, 1 / 12)
  const monthlyPayment =
    principalAmount *
    (Math.pow(interestFactor, termInMonths) /
      (Math.pow(interestFactor, termInMonths) - 1)) *
    (interestFactor - 1)
  return monthlyPayment
}

// Generates slider marks
// adjustRight and adjustLeft make sure the first and last mark are aligned with the slider handle
const SliderMarks = ({
  marks,
  adjustRight = false,
  adjustLeft = false,
}: {
  marks: number[]
  adjustRight?: boolean
  adjustLeft?: boolean
}) => {
  return (
    <div className="mt-1 flex w-full justify-between text-xs sm:text-base">
      {marks.map((mark) => (
        <span
          key={mark}
          className={cn(
            'w-18 group relative select-none text-gray-500 first:ml-1',
            {
              'group first:w-8 last:w-9': adjustRight,
              'first:-mr-9': adjustLeft,
            },
          )}
        >
          <span
            className={cn({
              'group-first:absolute group-first:left-0 group-last:absolute group-last:right-0':
                adjustRight,
            })}
          >
            {formatNumber(mark, 0)}
          </span>
        </span>
      ))}
    </div>
  )
}

interface LoanCalculatorModalProps {
  headerText?: string
  buttonText: string
  children: React.ReactNode
}

function LoanCalculatorModal({
  headerText,
  children,
  buttonText,
}: Readonly<LoanCalculatorModalProps>) {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="text-primary underline hover:text-gray"
      >
        {buttonText}
      </button>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        {headerText && <Modal.Header>{headerText}</Modal.Header>}
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  )
}
